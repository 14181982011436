import { withRouter } from "react-router";
import { useEffect, useState } from "react";
import db from "src/firebase";
import { doc, getDoc } from "@firebase/firestore";
import Loader from "src/components/Loader";
import { getDocs, collection, query, where } from "@firebase/firestore";

import { Link } from "react-router-dom";

function Instructor() {
  const [instructor, setInstructor] = useState();
  const [courses, setCourses] = useState([]);
  const url = window.location.href;
  const id = url.split("/")[url.split("/").length - 1];
  useEffect(() => {
    getDoc(doc(db, "instructors", id)).then((doc) => {
      setInstructor(doc);
      const items = [];

      const q = query(
        collection(db, "courses"),
        where("instructor", "==", doc.data().instructor)
      );

      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          items.push(doc);
        });

        setCourses(items);
      });
    });
  }, []);

  if (!instructor) {
    return <Loader />;
  }

  return (
    <section className="py-8">
      <div className="container px-4 mx-auto">
        <div className="mb-8 bg-white border border-gray-200 rounded-xl">
          <div className="relative">
            <div className="relative block w-full h-56 rounded-lg">
              <img className="absolute inset-0 w-full h-56 object-cover rounded-lg" src={instructor?.data().banner} alt=""/>
              <div className="absolute inset-0 bg-gradient-to-t rounded-lg from-gray-950 to-transparent"></div>
            </div>
            <div className="absolute bottom-0 left-0 w-full flex flex-wrap p-6 items-center justify-between">
              <div className="flex items-center w-full md:w-auto mb-5 md:mb-0">
                <img className="w-16 md:w-24 h-16 md:h-24 mr-2 md:mr-6 rounded-lg" src={instructor?.data().logo} alt=""/>
                <div>
                  <h5 className="text-2xl text-blue-50 font-semibold">{instructor?.data().instructor}</h5>
                  <span className="text-lg text-gray-300">{instructor?.data().specialty}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/3 px-4 mb-6 lg:mb-0">
            <div className="p-6 mb-6 bg-white border border-gray-200 rounded-xl">
              <h3 className="text-lg font-semibold text-gray-950 mb-6">Sobre mim</h3>
              <p className="leading-normal text-gray-600 font-regular">{instructor?.data().about}</p>
            </div>
            <div className="p-6 mb-6 bg-white border border-gray-200 rounded-xl">
              <h3 className="text-lg font-semibold text-gray-950 mb-4">Detalhes</h3>
              <table className="table-auto w-full text-md lg:mb-0 mb-6">
                <tbody>
                  <tr>
                    <td className="text-md font-medium text-gray-900 md:mt-4 mt-6 mb-3">Website</td>
                    <td className="text-right font-regular text-gray-500 text-md">
                      <a 
                      target={"_blank"}
                      href={`${instructor?.data().website}`}
                      className="text-right font-regular text-gray-500 text-md"
                      >
                        {instructor?.data().website}
                      </a>
                    </td>
                  </tr>
                  <tr className="">
                    <td className="text-md font-medium text-gray-900 md:mt-4 mt-6 mb-3">CRM</td>
                    <td className="text-right font-regular text-gray-500 text-md">{instructor?.data().crm}</td>
                  </tr>
                  <tr>
                    <td className="text-md font-medium text-gray-900 md:mt-4 mt-6 mb-3">Certificates</td>
                    <td className="text-right font-regular text-gray-500 text-md">{instructor?.data().background}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full lg:w-2/3 px-4">

            <div className="flex items-center justify-center px-8 py-6 bg-white border border-gray-200 rounded-xl">
              <h3 className="text-lg text-center font-semibold text-gray-950">Meus Cursos</h3>
            </div>

            <div className="grid md:grid-cols-3 gap-6 mt-6 grid-cols-1">
              {courses.map((course, id) => (
                <Link
                  key={id}
                  to={`/courses/${course.id}`}
                  className="group w-full bg-white border border-gray-200 rounded-lg p-6 h-96 text-left relative overflow-hidden"
                >
                  <img className="absolute inset-0 w-full h-96 object-cover rounded-lg" src={instructor?.data().banner} alt=""/>
                  <div className="absolute inset-0 bg-gradient-to-t rounded-lg from-gray-950 to-transparent"></div>
                  <div className="absolute bottom-6 z-20 flex flex-row items-center text-left">
                    <img
                      className="w-14 h-14 rounded-lg mr-4"
                      src={course.data().logo}
                      alt="Logo"
                    />
                    <div>
                      <p className="text-xl font-semibold text-white line-clamp-2 overflow-hidden leading-none">
                        {course.data().title}
                      </p>
                      <p className="text-xs font-medium py-1 mt-2 bg-blue-200 text-center rounded-full text-blue-600">
                        {course.data().category}
                      </p>
                    </div>
                  </div>
                  <p className="absolute bottom-6 right-6">
                    {/*transform translate-x-10 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-500*/}
                    <span className="text-lg font-semibold text-white">
                    R$ {course.data().price}
                    </span>
                  </p>
                </Link>
              ))}
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Instructor);
