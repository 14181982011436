import InputField from "src/components/InputField";
import AdminLayout from "src/layouts/AdminLayout";
import PaymentLinkAd from "src/components/PaymentLinkAd";
import db, { useAuthState } from "src/firebase";
import ReactQuill from "react-quill";
import { useCallback, useEffect, useState } from "react";
import {
  addDoc,
  doc,
  setDoc,
  collection,
  Timestamp,
} from "@firebase/firestore";
import { storage } from "src/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { useHistory, Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";

export default function PaymentLinkCreator({ paymentlinkToEdit, id }) {
  let history = useHistory();
  const { user } = useAuthState();

  const [paymentlink, setPaymentLink] = useState(
    paymentlinkToEdit || {
      instructor: user.displayName,
      instructorId: user.uid,
      title: "",
      price: 0,
      paymentUrl: "",
      description: "",
      status: "Disponível",
      time: Timestamp.now(),
    }
  );

  const [description, setDescription] = useState(paymentlink?.description);

  useEffect(() => {
    setPaymentLink({ ...paymentlink, description: description });
  }, [description]);

  let isComplete =
    paymentlink.title.length > 0 &&
    paymentlink.price.length > 0 &&
    paymentlink.description.length > 0;

  
  const addToDatabase = useCallback(async () => {
    if (paymentlinkToEdit && id) {
      await setDoc(doc(db, "paymentlinks", id), paymentlink);
    } else {
      await addDoc(collection(db, "paymentlinks"), paymentlink);
    }
    history.push("/admin");
  }, [paymentlink, user, db, id]);

  const chapters = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };
  
  function WaitingBtn() {
    return (
      <div className="cursor-not-allowed transform ease-in w-72 duration-100 flex mr-2 items-center font-semibold text-md justify-center px-8 py-3 bg-transparent border border-gray-200 rounded-lg text-gray-900">
        Aguardando respostas...
      </div>
    );
  }

  return (
    <AdminLayout>
      <div className="grid grid-cols-12 overflow-y-hidden h-screen">
        <div className="col-span-4  px-12 py-4 overflow-y-scroll">
          <InputField
            className="mt-8"
            type="text"
            label="Título do Link de Pagamento"
            value={paymentlink.title}
            onChange={(e) => {
              setPaymentLink({
                ...paymentlink,
                title: e.target.value,
              });
            }}
            placeholder=""
          />
          <div className="grid grid-cols-4 gap-4 mt-6">
            <InputField
              className="col-span-2"
              type="number"
              label="Valor em R$"
              placeholder="2500"
              value={paymentlink.price}
              onChange={(e) => {
                setPaymentLink({
                  ...paymentlink,
                  price: e.target.value,
                });
              }}
            />
          </div>
          <label className="block text-sm font-semibold text-gray-950 mt-6 mb-2">
            Descrição do Link de Pagamento
          </label>

          <ReactQuill
            chapters={chapters}
            theme="snow"
            value={description}
            onChange={setDescription}
            placeholder="Digite aqui a descrição do seu Link de Pagamento (Exemplo: Pagamento da mentoria)..."
          />

          <div className="flex items-center pt-6">
            {isComplete ? (
              <button
                className="text-center font-medium transform hover:bg-gray-900 cursor-pointer text-md px-8 py-3 bg-gray-800 rounded-lg text-white"
                onClick={() => addToDatabase()}
              >
                Criar Link de Pagamentos
              </button>
            ) : (
              <WaitingBtn />
            )}

            <Link
              to="/admin"
              className="ml-2 font-medium mr-2 cursor-pointer border-b-2 border-gray-900  px-8 py-3 rounded-lg border-none"
            >
              Cancelar
            </Link>
          </div>
        </div>
        <div className="col-span-8 overflow-y-scroll">
          <PaymentLinkAd 
            paymentlink={paymentlink} 
            description={paymentlink.description} 
            instructor={paymentlink.instructor} 
            instructorId={paymentlink.instructorId} 
          />
        </div>
      </div>
    </AdminLayout>
  );
}

