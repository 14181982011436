import InputField from "src/components/InputField";

export default function Student({ student, addStudent }) {
  return (
    <div className="container mx-auto px-4">
      <div className="pb-16 mx-auto bg-white rounded-3xl md:max-w-2xl border border-gray-200 px-6 md:px-0">
        <div className="md:max-w-md mx-auto">
          <h2 className="mt-16 mb-16 md:text-4xl text-2xl font-semibold text-center items-center justify-center align-middle text-gray-900 leading-none">
            Confirme seus dados
          </h2>
    
          <InputField
            type="text"
            label="Seu nome"
            value={student.name}
            onChange={(e) => addStudent({ ...student, name: e.target.value })}
            placeholder="Maria Araujo"
          />
    
          <InputField
            type="text"
            label="Seu email"
            value={student.email}
            onChange={(e) => addStudent({ ...student, email: e.target.value })}
            placeholder="nome@email.com"
          />
        </div>
      </div>
    </div>
  );
}