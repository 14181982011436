import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Menu, X } from "react-feather";
import { Link } from "react-router-dom";
import { useRef } from "react";

export default function MobileMenu() {
  const buttonRef = useRef();
  return (
    <div className="px-4">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              ref={buttonRef}
              className="md:hidden block text-gray-950 group pt-2.5 bg-gray-200 rounded-md items-center p-2 font-medium hover:text-gray-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-opacity-75"
            >
              {open ? <X className="text-2xl text-gray-950 px-1 pb-0.5" /> : <Menu className="text-2xl text-gray-950 px-1 pb-0.5" />}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="lg:hidden block absolute -left-24 z-10 w-60 max-w-sm mt-2 transform -translate-x-1/2 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden bg-white rounded-lg border-gray-200 border shadow-2xl">
                  <div className="relative p-3">
                    <Link
                      onClick={() => buttonRef.current?.click()}
                      to="/courses"
                      className="flex items-center text-left p-2 mx-1 my-2 rounded-md text-md font-medium text-gray-950 hover:text-gray-800 transition-colors duration-300"
                    >
                      Cursos
                    </Link>

                    <Link
                      onClick={() => buttonRef.current?.click()}
                      to="/instructors"
                      className="flex items-center text-left p-2 mx-1 my-2 rounded-md text-md font-medium text-gray-950 hover:text-gray-800 transition-colors duration-300"
                    >
                      Instrutores
                    </Link>

                    <a
                      href="https://www.medfy/net"
                      className="flex items-center text-left p-2 mx-1 my-2 rounded-md text-md font-medium text-gray-950 hover:text-gray-800 transition-colors duration-300"
                    >
                      Venda seu curso
                    </a>

                    <Link
                      onClick={() => buttonRef.current?.click()}
                      to="/faq"
                      className="flex items-center text-left p-2 mx-1 my-2 rounded-md text-md font-medium text-gray-950 hover:text-gray-800 transition-colors duration-300"
                    >
                      Perguntas frequentes
                    </Link>

                    <div className="w-full md:w-auto p-2">
                      <Link
                        to="/sign-in"
                        className="block w-full px-6 py-2 text-center text-gray-950 font-medium transition duration-200 ease-in hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 rounded-lg text-md border border-gray-200"
                      >
                        Entrar
                      </Link>
                    </div>
                    <div className="w-full md:w-auto p-2">
                      <Link
                        to="/sign-up"
                        className="block w-full px-6 py-2 text-center text-white font-medium bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 rounded-lg text-md border border-blue-400"
                      >
                        Cadastre-se
                      </Link>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
