import { Fragment } from "react";
import { getAuth, signOut } from "@firebase/auth";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDown, Settings, Grid, BarChart2, User, LogOut } from "react-feather";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function ProfileMenu({ role }) {
  const auth = getAuth();
  const user = auth.currentUser;
  let history = useHistory();

  function handleSignOut() {
    history.push("/");
    signOut(getAuth());
  }

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex relative pt-0.5 z-10">
            {user?.photoURL ? (
              <img
                className="h-10 w-10 rounded-lg object-cover aspect-square"
                src={user.photoURL}
                alt=""
              />
            ) : (
              <div className="flex items-center justify-center h-10 w-10 rounded-lg bg-transparent border border-gray-300">
                <User className="text-gray-950" />
              </div>
            )}

            <ChevronDown className="ml-1 h-5 w-5 bottom-0 mt-5 text-gray-950" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-8 z-50 origin-top-right bg-white divide-y divide-gray-800 rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
            {role === "student" ? (
              <div className="p-3">
                <Menu.Item>
                  <Link
                    to="/purchases"
                    className="flex flex-row items-center text-left p-2 transition duration-200 ease-in rounded-lg hover:bg-gray-200 text-md text-gray-950"
                  >
                    <Grid className="mr-3 h-5 w-5 ml-2 text-gray-950" />
                    Início
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="/student/settings"
                    className="flex flex-row items-center text-left p-2 transition duration-200 ease-in rounded-lg hover:bg-gray-200 text-md text-gray-950"
                  >
                    <Settings className="mr-3 h-5 w-5 ml-2 text-gray-950" />
                    Configurações
                  </Link>
                </Menu.Item>
              </div>
            ) : (
              <div className="p-3">
                <Menu.Item>
                  <Link
                    to="/admin"
                    className="flex flex-row items-center text-left p-2 transition duration-200 ease-in rounded-lg hover:bg-gray-200 text-md text-gray-950"
                  >
                    <BarChart2 className="mr-3 h-5 w-5 ml-2 text-gray-950" />
                    Dashboard
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="/admin/settings"
                    className="flex flex-row items-center text-left p-2 transition duration-200 ease-in rounded-lg hover:bg-gray-200 text-md text-gray-950"
                  >
                    <Settings className="mr-3 h-5 w-5 ml-2 text-gray-950" />
                    Configurações
                  </Link>
                </Menu.Item>
              </div>
            )}
            <div className="px-3 py-3">
              <Menu.Item>
                <button
                  className="flex flex-row items-center text-left p-2 w-full transition duration-200 ease-in rounded-lg hover:bg-gray-200 pl-4 text-md text-red-700"
                  onClick={() => handleSignOut()}
                >
                  <LogOut className="mr-3 h-5 w-5 text-gray-950" />
                  Sair
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
