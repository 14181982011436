import { InstructorBoard } from "src/components/InstructorBoard";

function Courses() {
  return (
    <div className="pt-8 md:pt-12  h-screen">
      <InstructorBoard />
    </div>
  );
}

export default Courses;
