import React from "react";
import { useCallback, useEffect, useState } from "react";
import AdminLayout from '../../layouts/AdminLayout';
import { DollarSign, Navigation, FileText, AlertCircle } from "react-feather";
import InputField from "src/components/InputField";
import db, { useAuthState } from "src/firebase";
import {
  addDoc,
  doc,
  setDoc,
  collection,
  getDocs,
  Timestamp,
} from "@firebase/firestore";
import { useHistory, Link } from "react-router-dom";

export default function AdminPayments({ id }) {
  let history = useHistory();
  const { user } = useAuthState();

  const [bankDetails, setBankDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [availableBalance, setAvailableBalance] = useState(0);
  const [inTransit, setInTransit] = useState(0);
  const [estimatedTaxes, setEstimatedTaxes] = useState(0);

  const fetchBankDetails = async () => {
    try {
      const bankDetailsRef = collection(db, "instructors", user.uid, "bankDetails");
      const querySnapshot = await getDocs(bankDetailsRef);
  
      querySnapshot.forEach((doc) => {
        setBankDetails(doc.data());
      });
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };  
  
  useEffect(() => {
    if (user) {
      fetchBankDetails();
    }
  }, [user]);

  useEffect(() => {
    if (bankDetails) {
      setAvailableBalance(bankDetails.availableBalance);
      setInTransit(bankDetails.inTransit);
      setEstimatedTaxes(bankDetails.estimatedTaxes);
    }
  }, [bankDetails]);

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };  


  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [banks, setBanks] = useState([]);

  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = async () => {
    try {
      const response = await fetch("https://brasilapi.com.br/api/banks/v1");
      const data = await response.json();
      setBanks(data);
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };

  const [bankDetail, setBankDetail] = useState({
      instructorId: user.uid,
      availableBalance: 0,
      inTransit: 0,
      estimatedTaxes: 0,
      agency: "",
      account: "",
      bank: "",
      pix: ""
    }
  );

  const [agency, setAgency] = useState(bankDetail.agency);
  const [account, setAccount] = useState(bankDetail.account);
  const [bank, setBank] = useState(bankDetail.bank);
  const [pix, setPix] = useState(bankDetail.pix);

  useEffect(() => {
    setBankDetail({
      ...bankDetail,
      agency,
      account,
      bank,
      pix,
    });
  }, [agency, account, bank, pix]);

  const addToDatabase = useCallback(async () => {
    const bankDetailsRef = collection(db, "instructors", user.uid, "bankDetails");
  
    if (id) {
      await setDoc(doc(bankDetailsRef, id), bankDetail);
    } else {
      await addDoc(bankDetailsRef, bankDetail);
    }
    handleCloseModal();
    window.location.reload();
  }, [bankDetail, user, db, id]);  
  
  return (
    <AdminLayout>
      <section className="pt-16 h-screen md:mx-8 mx-4">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-3/5 px-3 mb-6 lg:mb-0">
              <div className="mb-6">
                <div className="flex flex-wrap justify-center -mx-3 -mb-6">
                  <div className="w-full md:w-1/3 px-3 mb-6">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl">
                      <div className="max-w-xxs px-6 md:px-0 md:max-w-none mx-auto">
                        <div className="flex flex-wrap items-center -m-2">
                          <div className="w-auto p-2 self-start">
                            <div className="flex w-8 h-8 items-center justify-center bg-gray-200 rounded-md">
                              <DollarSign className="h-4 w-4 text-gray-600" />
                            </div>
                          </div>
                          <div className="w-auto p-2">
                            <h5 className="text-sm leading-tight font-medium text-gray-600 mb-1">Disponível para saque</h5>
                            <h4 className="leading-6 text-lg text-gray-900 font-semibold">R${formatNumberWithCommas(availableBalance ? availableBalance.toFixed(2) : "0.00")}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-6">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl">
                      <div className="max-w-xxs px-6 md:px-0 md:max-w-none mx-auto">
                        <div className="flex flex-wrap items-center -m-2">
                          <div className="w-auto p-2 self-start">
                            <div className="flex w-8 h-8 items-center justify-center bg-gray-200 rounded-md">
                              <Navigation className="h-4 w-4 text-gray-600" />
                            </div>
                          </div>
                          <div className="w-auto p-2">
                            <h5 className="text-sm leading-tight font-medium text-gray-600 mb-1">Valores em trânsito</h5>
                            <h4 className="leading-6 text-lg text-gray-900 font-semibold">R${formatNumberWithCommas(inTransit ? inTransit.toFixed(2) : "0.00")}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-6">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl">
                      <div className="max-w-xxs px-6 md:px-0 md:max-w-none mx-auto">
                        <div className="flex flex-wrap items-center -m-2">
                          <div className="w-auto p-2 self-start">
                            <div className="flex w-8 h-8 items-center justify-center bg-gray-200 rounded-md">
                              <FileText className="h-4 w-4 text-gray-600" />
                            </div>
                          </div>
                          <div className="w-auto p-2">
                            <h5 className="text-sm leading-tight font-medium text-gray-600 mb-1">Impostos estimados</h5>
                            <h4 className="leading-6 text-lg text-gray-900 font-semibold">R${formatNumberWithCommas(estimatedTaxes ? estimatedTaxes.toFixed(2) : "0.00")}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="pt-6 pb-14 px-6 bg-white border border-gray-200 rounded-xl">
                  <div className="flex flex-wrap -mx-3 mb-6 justify-between items-center">
                    <div className="w-auto px-3">
                      <h4 className="text-lg text-gray-900 font-semibold tracking-wide">Últimas vendas</h4>
                    </div>
                    <div className="w-auto px-3">
                      <div className="w-full sm:w-auto mt-4 sm:mt-0">
                        <div className="relative inline-block max-w-max bg-white border border-gray-200 px-2 rounded-md">
                          <select className="relative py-3 pl-2 pr-6 cursor-pointer bg-transparent text-xs text-gray-600 font-semibold appearance-none outline-none" id="">
                            <option value="">Última semana</option>
                            <option value="">Último mês</option>
                          </select>
                          <span className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2">
                            <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 1.5L5 5.5L9 1.5" stroke="#3D485B" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="text-sm font-regular text-gray-500">Nada por enquanto</span>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/5 px-3">
              <div className="p-6 bg-white border border-gray-200 rounded-xl">
                <div className="flex flex-wrap -mx-3 mb-6 justify-between items-center">
                  <div className="w-auto px-3">
                    <h4 className="text-lg text-gray-900 font-semibold">Conta</h4>
                  </div>
                </div>
                <div className="px-3 md:px-14 pb-6 mb-8 border-b border-gray-400">

                  <div className="w-full mb-6">
                    <div className="mx-auto py-8 bg-gradient-to-r from-blue-500 to-blue-700 px-8 rounded-xl">
                      <div className="mx-auto text-left">
                        <span className="text-sm text-blue-200 font-medium">Saldo disponível</span>
                        <h4 className="text-3xl leading-8 text-white font-semibold mb-4">R${formatNumberWithCommas(availableBalance ? availableBalance.toFixed(2) : "0.00")}</h4>
                      </div>
                      <button className="hover:bg-blue-700 transition duration-200 ease-in text-white border border-white py-3 px-6 rounded-lg text-md font-medium">
                        Efetuar saque
                      </button>
                    </div>
                  </div>

                  {!bankDetails && (
                    <button 
                      className="flex bg-yellow-100 flex-row text-sm w-full h-12 py-2 px-3 items-center justify-center text-gray-900 font-medium border border-yellow-200 hover:bg-yellow-200 transition duration-200 rounded-lg"
                      onClick={handleOpenModal}
                    >
                      <AlertCircle className="h-5 w-5 mr-4 text-yellow-500" />
                      Adicione seus dados bancários para efetuar saques
                    </button>
                  )}
                  {openModal ? ( 
                    <div className="flex items-center justify-center mt-6">
                      <div className="bg-white border border-gray-200 w-full max-w-md p-6 rounded-lg">
                        <h2 className="text-lg text-center font-semibold mb-4">Seus dados para recebimento</h2>
                          <div className="mb-4">
                            <InputField
                              id="agency"
                              label="Agência"
                              type="number"
                              placeholder="0001"
                              value={agency}
                              onChange={(e) => setAgency(e.target.value)}
                            />

                          </div>
                          <div className="mb-4">
                            <InputField
                              id="account"
                              label="Conta"
                              placeholder="00000000-0"
                              type="text"
                              value={account}
                              onChange={(e) => setAccount(e.target.value)}
                            />
                          </div>
                          <div className="mb-4">
                            <label htmlFor="bank" className="block text-sm font-medium text-gray-700">
                              Banco
                            </label>
                            <select
                              id="bank"
                              value={bank}
                              onChange={(e) => setBank(e.target.value)}
                              className="mt-1 block w-full py-3 px-3 border border-gray-200 bg-white rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                              <option value="" disabled>
                                Selecione o banco
                              </option>
                              {banks.map((bankItem) => (
                                <option key={bankItem.code} value={`${bankItem.name} - ${bankItem.code}`}>
                                  {bankItem.name} - {bankItem.code}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-4">
                            <InputField
                              id="pix"
                              label="Pix"
                              type="text"
                              placeholder="CPF, Telefone ou email."
                              value={pix}
                              onChange={(e) => setPix(e.target.value)}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                          <button
                            className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-6 rounded-md focus:outline-none focus:shadow-outline"
                            type="submit"
                            onClick={addToDatabase}
                          >
                            Salvar
                          </button>
                          <button
                            className="bg-white border border-gray-200 hover:bg-gray-100 text-gray-900 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleCloseModal}
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </div>
                  ) : null}
                </div>
                <div className="md:px-14">
                  <div className="flex mb-6 items-center justify-between">
                    <h4 className="text-lg text-gray-800 font-semibold">Atividade recente</h4>
                    <a className="inline-block text-sm text-gray-500 hover:text-gray-700 font-medium tracking-wide" href="#">Ver extrato</a>
                  </div>
                  {/*
                    <div className="flex mb-6 items-center justify-between">
                      <div className="flex items-center">
                        <div className="flex w-12 h-12 mr-3 items-center justify-center bg-gray-200 rounded-full">
                          <img src="trizzle-assets/logos/s-stripe.svg" alt=""/>
                        </div>
                        <div>
                          <h5 className="text-sm font-semibold text-gray-800">Stripe</h5>
                          <span className="text-xs text-gray-400 font-semibold">Deposit</span>
                        </div>
                      </div>
                      <div className="text-right">
                        <h6 className="text-sm text-gray-600 font-semibold">+$523.10</h6>
                        <span className="text-xs text-gray-400 font-semibold">Today at 7.18 AM</span>
                      </div>
                    </div>
                  */}
                  <span className="text-sm font-regular text-gray-500 ">Nada por enquanto</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
}