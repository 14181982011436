import { Link } from "react-router-dom";
import db from "src/firebase";
import { useState, useEffect } from "react";
import { getDocs, collection, query } from "@firebase/firestore";
import Loader from "./Loader";

export function InstructorBoard() {
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    const items = [];
    const q = query(collection(db, "instructors"));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        items.push(doc);
      });
      setInstructors(items);
    });
  }, []);

  if (instructors.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <div className="">
        <div className="container mx-auto px-4 pb-8">
          <h2 className="text-3xl font-semibold text-gray-900 leading-none line-clamp-1 overflow-hidden mb-8 tracking-tight">
            Médicos em destaque
          </h2>
          <div className="grid lg:grid-cols-3 md:gap-6 gap-10 grid-cols-1 ">
            {instructors.map((instructor, id) => (
              <div
                key={id}
                className="transform ease-in duration-100  w-full bg-white border border-gray-200 rounded-lg p-6 text-left"
              >
                <div className="flex items-center text-left pb-4">
                  <img
                    className="w-16 h-16 rounded-lg mr-4 border border-gray-200"
                    src={instructor.data().logo}
                    alt="Logo"
                  />
                  <div>
                    <p className="text-2xl font-semibold text-gray-900 leading-none line-clamp-1 overflow-hidden">
                      {instructor.data().instructor}
                    </p>
                  </div>
                </div>
                <p className="pl-1">
                  <span className="text-md font-regular line-clamp-2 overflow-hidden">{instructor.data().about}</span>
                </p>

                <div className="flex items-center pt-6">
                  <Link
                    to={`/instructors/${instructor.id}`}
                    className="hover:bg-gray-100 transition ease-in duration-200 border border-gray-200 flex cursor-pointer w-full md:w-auto items-center font-medium text-md justify-center px-8 py-3 bg-white rounded-lg mb-4 md:mb-0 md:mr-4 text-gray-900"
                  >
                    Visitar perfil
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
