import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "src/components/Select";
import db from "src/firebase";

import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import PurchaseCount from "../PurchaseCount";
import { Search } from "react-feather";

const times = ["Mais recentes", "Menos recentes"];
const th = ["Curso", "Módulos", "Alunos", "Criado em"];

export default function CourseTable({ courses }) {
  let [displayedCourses, setDisplayedCourses] = useState([]);

  let [selectedTime, setSelectedTime] = useState(times[0]);
  const history = useHistory();

  useEffect(() => {
    setDisplayedCourses(courses);
  }, [courses]);

  function handleClick(id) {
    history.push(`/admin/${id}`);
  }

  function calculateDays(date) {
    let daysAgo = Math.floor((new Date() - date) / (1000 * 3600 * 24));

    if (daysAgo < 1) {
      return "Hoje";
    } else if (daysAgo < 2) {
      return daysAgo + " dia atrás";
    } else return daysAgo + " dias atrás";
  }

  function search(input) {
    var tmp = [];
    const filter = input.toUpperCase();
    courses.forEach((course) => {
      if (course.data().title.toUpperCase().indexOf(filter) > -1) tmp.push(course);
    });

    setDisplayedCourses(tmp);
  }

  async function findNumberOfPurchases(id) {
    const q = query(collection(db, "purchases"), where("course", "==", id));

    let count = 0;

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      count++;
    });

    console.log(count);

    return count;
  }

  function time(input) {
    setSelectedTime(input);

    if (input === "Menos recentes")
      displayedCourses.sort(
        (a, b) => a.data().time.toMillis() - b.data().time.toMillis()
      );
    else
      displayedCourses.sort(
        (a, b) => b.data().time.toMillis() - a.data().time.toMillis()
      );
  }

  return (
    <>
      <div className="mt-8 flex justify-between gap-3">
        <div className="relative w-full">
          <Search
            className="absolute w-5 h-5 top-3.5 left-4 text-gray-600"
          />

          <input
            className="h-12 w-2/3 text-md block text-gray-700 rounded-lg border border-grey-light p-3 focus:ring-gray-900 focus:border-gray-900 py-3 pl-12 px-4 leading-tight focus:outline-none"
            placeholder="Pesquisar cursos..."
            onChange={(e) => search(e.target.value)}
          />
        </div>

        <div className="flex gap-3">
          <Select
            className="w-48"
            selected={selectedTime}
            statuses={times}
            changeStatus={time}
          />
        </div>
      </div>
      <div className="mt-12 overflow-x-auto bg-white">
        <table className="min-w-full z-0">
          <thead className="border-b border-gray-500">
            <tr>
              {th.map((t) => (
                <th className="px-6 py-3 text-left text-xs text-gray-900 uppercase tracking-wider leading-tight font-semibold">
                  {t}
                </th>
              ))}
            </tr>
          </thead>

          {displayedCourses.length != 0 ? (
            <tbody className="divide-y divide-gray-300 divide-dashed">
              {displayedCourses.map((currentCourse) => (
                <tr key={currentCourse.id} className="transition duration-200 ease-in hover:">
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                    onClick={() => handleClick(currentCourse.id)}
                  >
                    {currentCourse.data().title}
                  </td>
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                    onClick={() => handleClick(currentCourse.id)}
                  >
                    {currentCourse.data().modules}
                  </td>
                  <PurchaseCount id={currentCourse.id} handleClick={handleClick} />
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                    onClick={() => handleClick(currentCourse.id)}
                  >
                    {calculateDays(currentCourse.data().time.toDate())}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            ""
          )}
        </table>

        {displayedCourses.length === 0 ? (
          <h3 className="mt-20 text-center text-gray-500 font-normal">Nenhum curso encontrado</h3>
        ) : (
          " "
        )}
      </div>
    </>
  );
}
