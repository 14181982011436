import React from "react";
import StudentLayout from "src/layouts/StudentLayout";

export default function StudentProfile() {

  return (
    <StudentLayout>
    </StudentLayout>
  );
}
