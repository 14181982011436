import { Link } from "react-router-dom";

export default function NoPurchases() {
  return (
    <div className="text-center md:w-5/12 w-11/12 mx-auto md:my-56 my-32 ">
      <h1 className="text-2xl mt-4">Ainda não possui uma conta?</h1>
      <p className="mb-8 text-regular text-gray-500">
        Crie uma conta para assistir seus cursos.
      </p>
      <div className="pt-3">
        <Link
          to="/sign-up"
          className="hover:bg-blue-700 cursor-pointer items-center font-medium text-md justify-center px-8 py-3 bg-blue-600 rounded-lg text-white transition duration-200 ease-in"
        >
          Cadastre-se
        </Link>

        <Link
          to="/sign-in"
          className="ml-4 font-medium mr-2 cursor-pointer transition duration-200 ease-in hover: px-3 py-3 rounded-lg bg-transparent border border-gray-200 hover:bg-gray-200"
        >
          Já tenho uma conta
        </Link>
      </div>
    </div>
  );
}
