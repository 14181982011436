import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import db, { useAuthState } from "src/firebase";
import { doc, getDoc } from "@firebase/firestore";
import { useRole } from "src/hooks/useRole";
import NoPurchases from "src/components/emptyStates/NoPurchases";
import StudentLayout from '../../layouts/StudentLayout';

export default function Purchases() {
  let [purchases, setPurchases] = useState([]);
  const { user } = useAuthState();
  const role = useRole();
  const history = useHistory();

  const fetchPurchases = async () => {
    const studentDoc = await getDoc(doc(db, "students", user.uid));
    const purchasesArray = [];

    for (const purchaseId of studentDoc.data().purchases) {
      const purchaseDoc = await getDoc(doc(db, "purchases", purchaseId));
      const purchaseData = purchaseDoc.data();

      if (purchaseData && purchaseData.course) {
        const courseDoc = await getDoc(doc(db, "courses", purchaseData.course));
        const courseData = { ...courseDoc.data(), id: courseDoc.id };

        purchasesArray.push({
          purchaseId: purchaseId,
          courseId: courseDoc.id,
          courseTitle: courseData.title,
          courseLogo: courseData.logo,
          courseCategory: courseData.category,
          courseDescription: courseData.description,
        });
      }
    }

    setPurchases(purchasesArray);
  };

  useEffect(() => {
    if (user) {
      fetchPurchases();
    }
  }, [user]);

  if (role === "none") {
    return <NoPurchases />;
  }

  console.log(purchases);

  return (
    <StudentLayout>
      <section className="py-10 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 rounded-3xl">
            <div className="max-w-7xl mx-auto">
              <div className="mb-12 md:max-w-4xl mx-auto text-center">
                <h2 className="font-heading mb-6 text-4xl text-gray-900 font-semibold tracking-tight">
                  Seus cursos
                </h2>
                <p className="md:max-w-md mx-auto text-gray-500 font-medium">
                  Assista a todos os cursos que já comprou, ou explore novos cursos clicando no botão abaixo.
                </p>
              </div>
              <div className="max-w-5xl mx-auto">
                <div className="flex flex-wrap -m-5 mb-10">
                  {purchases.map((purchase, index) => (
                    <div
                      key={index}
                      className="w-full p-5 cursor-pointer"
                      onClick={() =>
                        history.push(`/purchases/watch/${purchase.courseId}`)
                      }
                    >
                      <div className="flex flex-wrap h-full bg-white overflow-hidden rounded-3xl">
                        <div className="w-full md:w-1/2">
                          <img
                            className="w-full h-full object-cover"
                            src={purchase.courseLogo}
                            alt=""
                          />
                        </div>
                        <div className="flex-1">
                          <div className="md:max-w-lg p-10 h-full">
                            <div className="flex flex-col justify-between h-full">
                              <div className="flex-initial mb-8">
                                <p className="mb-3 text-sm text-gray-500 font-bold">
                                  {purchase.courseCategory}
                                </p>
                                <a className="group inline-block mb-4" href="#">
                                  <h3 className="font-heading text-2xl text-gray-900 hover:text-gray-700 group-hover:underline font-semibold">
                                    {purchase.courseTitle}
                                  </h3>
                                </a>
                                <p className="text-gray-500 font-regular line-clamp-4 overflow-hidden">
                                  {purchase.courseDescription}
                                </p>
                              </div>
                              <div className="flex-initial">
                                <div className="flex flex-wrap -m-2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-wrap md:justify-center -m-2">
                <div className="w-full md:w-auto p-2">
                  <Link
                    to={"/courses"}
                    className="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:ring-gray-600 rounded-full"
                  >
                    Explorar mais cursos
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StudentLayout>
  );
}
      
