import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import EbookCreator from "src/components/EbookCreator";

export default function AdminAddEbook() {
  return (
    <>
      <EbookCreator />
    </>
  );
}
