import { useState } from "react";
import { useHistory } from "react-router-dom";
import InputField from "src/components/InputField";
import { Link } from "react-router-dom";
import GoogleIcon from "../../images/google.svg"
import db from "src/firebase";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  doc,
  setDoc,
  Timestamp,
  collection,
  query,
  where,
  getDocs,
} from "@firebase/firestore";
import { Eye, EyeOff } from "react-feather";

function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [news, setNews] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const history = useHistory();

  let allFieldsChecked =
    name.length > 0 &&
    email.length > 0 &&
    password.length > 0;

  async function getPurchases(email) {
    let purchases = [];
    const purchasesRef = collection(db, "purchases");
    const q = query(purchasesRef, where("student.email", "==", email));
    await getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        purchases.push(doc.id);
        console.log(doc.id);
      });
    });

    return purchases;
  }

  async function handleGoogleSignUp() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
  
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;
      const uid = user.uid;
      const name = user.displayName;
  
      // Get purchases and set the document
      getPurchases(email).then((purchases) => {
        setDoc(doc(db, "students", uid), {
          name: name,
          email: email,
          news: news,
          purchases: purchases,
          created: Timestamp.now(),
        }).then(() => {
          history.push("/purchases");
        });
      });
    } catch (error) {
      console.log(error);
      setErrorMessage("Parece que algo deu errado. Tente novamente mais tarde.");
    }
  }  

  function handleLogin() {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        let uid = userCredential.user.uid;
        getPurchases(email).then((purchases) => {
          setDoc(doc(db, "students", uid), {
            name: name,
            email: email,
            news: news,
            purchases: purchases,
            created: Timestamp.now(),
          }).then(() => {
            history.push("/purchases");
          });
        });
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Parece que algo deu errado. Tente novamente mais tarde.");
      });
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <section className="py-10 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="pt-12 pb-16 mx-auto bg-white rounded-3xl md:max-w-2xl border border-gray-200 px-6 md:px-0">
          <div className="md:max-w-md mx-auto">
            <div className="mb-10 text-center">
              <h2 className="font-heading mb-4 text-4xl tracking-tight text-gray-900 font-semibold">Boas vindas!</h2>
              <button
                className="w-full flex flex-row items-center justify-center mt-12 px-8 py-3 text-md text-center text-gray-900 font-medium bg-white border border-gray-200 transition duration-200 ease-in hover:bg-gray-100 focus:ring-4 focus:ring-red-200 rounded-lg"
                onClick={handleGoogleSignUp}
              >
                <img src={GoogleIcon} className="mr-4 w-6" />
                Entrar com o Google
              </button>
              <div className="flex flex-row mt-4">
                <div className="w-1/3 border-b border-gray-300"></div>
                <div className="w-1/3 text-gray-500 text-center">ou</div>
                <div className="w-1/3 border-b border-gray-300"></div>
              </div>
            </div>
            <div>
              <InputField
                type="text"
                label="Seu nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Maria Araújo"
              />
              <InputField
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="seuemail@email.com"
              />
              <div className="relative">
                <InputField
                  type={passwordVisible ? "text" : "password"}
                  label="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="*********"
                  />
                  <button
                      className="absolute top-9 right-2 text-gray-400 hover:text-gray-600 focus:outline-none"
                      onClick={togglePasswordVisibility}
                    >
                  {passwordVisible ? <div className="p-2 bg-gray-100 rounded-lg"><EyeOff className="h-5 w-5 text-gray-700"/></div> : <div className="p-2 bg-gray-100 rounded-lg"><Eye className="h-5 w-5 text-gray-700"/></div>}
                  </button>
              </div>
            </div>
            <label className="block text-sm font-regular mt-8 focus:outline-none outline-none">
              <input
              className="mr-2 leading-tight text-blue-700"
              type="checkbox"
              checked={news}
              onChange={() => setNews(!news)}
              />
              <span className="text-sm text-gray-500">
                Me mantenha informado sobre oportunidades e novos cursos
              </span>
            </label>
            <p className="text-xs text-center mt-2 mb-6 text-red-500">
            {errorMessage}
            </p>

            {allFieldsChecked ? (
              <button
                className="w-full block px-8 py-3.5 text-md text-center text-white font-medium bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 rounded-lg"
                onClick={() => handleLogin()}
              >
                Criar minha conta
              </button>
            ) : (
              <button className="cursor-not-allowed w-full block px-8 py-3.5 text-md text-center text-gray-500 font-medium bg-gray-300 rounded-lg">
                Criar minha conta
              </button>
            )}
            <div className="w-full pt-6">
              <p className="text-gray-500 text-center text-sm font-regular">
                <span>Quer vender seus cursos em vez disso? </span>
                <Link to="/sign-up/new-instructor" className="text-gray-700 hover:text-gray-800 font-semibold">
                  Para médicos
                </Link>
              </p>
              </div>
                <div className="w-full pt-3">
                  <p className="text-gray-500 text-center text-sm font-regular">
                    Ao criar uma conta, você concorda com a Política de Privacidade e os Termos e Condições da Medfy.
                  </p>
                </div>
              </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;