import db, { useAuthState } from "src/firebase";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "@firebase/firestore";

export function useCourses() {
  const [courses, setCourses] = useState([]);
  const { user } = useAuthState();

  useEffect(() => {
    const items = [];
    const q = query(collection(db, "courses"), where("instructorId", "==", user.uid));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        items.push(doc);
      });
      setCourses(items);
    });
  }, []);

  return courses;
}
