function About() {
  return (
    <div className="bg-blue-600">
      <div className="md:py-44 py-16 max-w-4xl mx-auto px-3 text-center">
        <h1 className="mx-auto md:text-7xl text-4xl font-semibold text-white pb-4">
          Sobre nós
        </h1>

        <p className="text-xl text-blue-200 font-regular">
          Olá, nós somos a Medfy. Mais em breve.
          <br />
          <br />
        </p>
      </div>
    </div>
  );
}

export default About;
