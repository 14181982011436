import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { AuthContextProvider } from "src/firebase";
import { useRole } from "./hooks/useRole";
import ScrollToTop from "./hooks/scrollToTop";

import Home from "./pages/landingPage/Home";
import Purchase from "./pages/landingPage/Purchase";
import PurchaseComplete from "./pages/landingPage/PurchaseComplete";
import Courses from "./pages/landingPage/Courses";
import Instructor from "./pages/landingPage/Instructor";
import PrivacyPolicy from "./pages/landingPage/PrivacyPolicy";
import TermsOfService from "./pages/landingPage/TermsOfService";
import Instructors from "./pages/landingPage/Instructors";
import Course from "./pages/landingPage/Course";
import Faq from "./pages/landingPage/Faq";
import About from "./pages/landingPage/About";
import SignIn from "./pages/landingPage/SignIn";
import SignUp from "./pages/landingPage/SignUp";
import SignUpInstructor from "./pages/landingPage/SignUpInstructor";
import ResetPassword from "./pages/landingPage/ResetPassword";

import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";

import Purchases from "./pages/student/Purchases";
import StudentFeed from "./pages/student/StudentFeed";
import StudentMessages from "./pages/student/StudentMessages";
import StudentProfile from "./pages/student/StudentProfile";
import Settings from "./pages/student/Settings";
import Watch from "./pages/student/Watch";

import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminProducts from "./pages/admin/AdminProducts";
import AdminCourses from "./pages/admin/AdminCourses";
import AdminSales from "./pages/admin/AdminSales";
import AdminServices from "./pages/admin/AdminServices";
import AdminPayments from "./pages/admin/AdminPayments";
import AdminProfile from "./pages/admin/AdminProfile";
import AdminSettings from "./pages/admin/AdminSettings";
import AdminCourse from "./pages/admin/AdminCourse";
import AdminPurchase from "./pages/admin/AdminPurchase";
import AdminAddCourse from "./pages/admin/AdminAddCourse";
import AdminAddEbook from "./pages/admin/AdminAddEBook";
import AdminAddPaymentLink from "./pages/admin/AdminAddPaymentLink";

function InstructorRoute({ component: C, ...props }) {
  return (
    <Route
      {...props}
      render={(routeProps) =>
        props.role === "instructor" ? (
          <C {...routeProps} />
        ) : (
          <Redirect to="/sign-in" />
        )
      }
    />
  );
}

function StudentRoute({ component: C, ...props }) {
  return (
    <Route
      {...props}
      render={(routeProps) =>
        props.role === "student" ? (
          <C {...routeProps} />
        ) : (
          <Redirect to="/sign-in" />
        )
      }
    />
  );
}

function App() {
  const role = useRole();
  console.log("role: " + role);

  return (
    <AuthContextProvider>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-of-service" component={TermsOfService} />
        <Route exact path="/instructors" component={Instructors} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/about" component={About} />
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/sign-in/reset-password" component={ResetPassword} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/sign-up/new-instructor" component={SignUpInstructor} />
        <Route exact path="/courses" component={Courses} />
        <Route exact path="/courses/:course/purchase" component={Purchase} />
        <Route exact path="/purchase-complete" component={PurchaseComplete} />
        <Route exact path="/instructors/:url" component={Instructor} />
        <Route exact path="/courses/:course" component={Course} />
        <Route exact path="/purchases" component={Purchases} role={role} />

        <StudentRoute exact path="/purchases/watch/:courseId" component={Watch} role={role}/>
        <StudentRoute exact path="/feed" component={StudentFeed} role={role}/>
        <StudentRoute exact path="/messages" component={StudentMessages} role={role}/>
        <StudentRoute exact path="/profile" component={StudentProfile} role={role}/>
        <StudentRoute exact path="/student/settings" component={Settings} role={role}/>

        <InstructorRoute exact path="/admin" component={AdminDashboard} role={role} />
        <InstructorRoute exact path="/products" component={AdminProducts} role={role}/>
        <InstructorRoute exact path="/products/new-course" component={AdminAddCourse} role={role}/>
        <InstructorRoute exact path="/products/new-ebook" component={AdminAddEbook} role={role}/>
        <InstructorRoute exact path="/products/new-payment-link" component={AdminAddPaymentLink} role={role}/>
        <InstructorRoute exact path="/edit/courses/" component={AdminCourses} role={role} />
        <InstructorRoute exact path="/sales" component={AdminSales} role={role} />
        <InstructorRoute exact path="/services" component={AdminServices} role={role} />
        <InstructorRoute exact path="/payments" component={AdminPayments} role={role} />
        <InstructorRoute exact path="/student/profile" component={AdminProfile} role={role} />
        <InstructorRoute exact path="/edit/settings" component={AdminSettings} role={role} />
        <InstructorRoute exact path="/admin/settings" component={AdminSettings} role={role}/>
        <InstructorRoute exact path="/talent-pool/:student" component={AdminPurchase} role={role}/>
        <InstructorRoute exact path="/admin/:course" component={AdminCourse} role={role}/>
        <InstructorRoute exact path="/admin/:course/:student" component={AdminPurchase} role={role}/>

        <Footer />
      </Router>
    </AuthContextProvider>
  );
}

export default App;
