import db, { useAuthState } from "src/firebase";
import { useState, useEffect } from "react";
import { updateEmail, updatePassword } from "firebase/auth";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import InputField from "src/components/InputField";
import { Check } from "react-feather";
import { Link } from "react-router-dom";
import StudentLayout from "src/layouts/StudentLayout";

function Settings() {
  const { user } = useAuthState();
  const ref = doc(db, "students", user.uid);
  let [loading, setLoading] = useState(false);
  let [saved, setSaved] = useState(false);
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  useEffect(() => {
    getDoc(ref).then((doc) => {
      let data = doc.data();
      setName(data.name);
      setEmail(data.email);
    });
  }, []);

  async function handleSave() {
    setLoading(true);
    await updateDoc(ref, {
      name: name,
      email: email,
    })
      .then(() => {
        updateEmail(user, email);
        updatePassword(user, password);
      })
      .then(() => {
        setSaved(true);

        setTimeout(function () {
          setSaved(false);
          setLoading(false);
        }, 1250);
      });
  }

  return (
    <StudentLayout>
      <div className=" md:pt-12 pt-8 pb-32">
        <div className="bg-white rounded-lg p-6 pb-10 overflow-x-auto mx-auto md:w-5/12 w-11/12 relative z-0">
          <h2 className="text-4xl font-semibold text-gray-900 leading-none text-center mt-4 mb-16">
            Configurações
          </h2>

          <div className="grid grid-cols-4 gap-4 mt-6">
            <InputField
              className="md:col-span-2 col-span-4"
              label="Nome"
              type="text"
              placeholder="Maria Araújo"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="grid grid-cols-4 gap-4 mt-2">
            <InputField
              className="md:col-span-2 col-span-4"
              label="Email"
              type="text"
              placeholder="seuemail@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputField
              className="md:col-span-2 col-span-4"
              label="Senha"
              type="password"
              placeholder="********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="w-full md:flex items-center justify-center pt-6 lg:justify-start md:space-x-5">
            <button
              className="hover:opacity-90 flex cursor-pointer items-center font-semibold text-md justify-center px-8 py-3 bg-gray-900 rounded-lg text-white hover:shadow-lg"
              onClick={() => handleSave()}
            >
              Salvar alterações
            </button>

            <Link
              to="/purchases"
              className="block z-10 w-full md:w-56 md:mx-0 text-gray-900 font-medium rounded-lg items-center justify-center text-center py-3 text-base border-gray-200 border hover:shadow-lg hover:opacity-80 duration-100"
            >
              Voltar para o Dashboard
            </Link>
          </div>

          {loading ? (
            <div
              className="absolute inset-0 flex justify-center items-center z-10"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.85)" }}
            >
              {!saved ? (
                <div>
                  <div className="animate-spin rounded-full mx-auto h-12 w-12 border-b-2 border-gray-900 mb-4"></div>{" "}
                  <p className="font-semibold text-lg">Salvando alterações...</p>
                </div>
              ) : (
                <div className="text-center">
                  <Check
                    className="text-5xl mb-3 text-blue-500"
                  />
                  <p className="font-semibold text-lg">Alterações salvas!</p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </StudentLayout>
  );
}

export default Settings;
