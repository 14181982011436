import React from "react";
import AdminLayout from '../../layouts/AdminLayout';
import { Grid, Video, Monitor, TrendingUp, CheckSquare, Award, Mail } from 'react-feather';

export default function AdminServices() {

  return (
    <AdminLayout>
      <section className="pt-16 h-screen md:mx-8 mx-4">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-4 -mb-6">
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div className="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-gray-200 rounded-lg">
                  <Video/>
                </div>
                <h4 className="text-xl text-gray-900 font-semibold mb-3">Medfy Studios</h4>
                <p className="text-gray-500 mb-6 font-regular text-sm">Gravação e edição com a qualidade e certificação Medfy.</p>
                <a className="px-8 py-3 border border-transparent font-medium rounded-lg text-white bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 md:py-2 text-md md:px-10 transform" target="_blank" href="https://api.whatsapp.com/send?phone=+5534998378252&text=Oi%20pessoal%20da%20Medfy.%20Podem%20me%20ajudar%20com%20um%20serviço?">Saiba mais</a>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div className="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-gray-200 rounded-lg">
                  <Monitor/>
                </div>
                <h4 className="text-xl text-gray-900 font-semibold mb-3">Criação de sites</h4>
                <p className="text-gray-500 mb-6 font-regular text-sm">Crie seu site com nossa equipe de programação.</p>
                <a className="px-8 py-3 border border-transparent font-medium rounded-lg text-white bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 md:py-2 text-md md:px-10 transform" target="_blank" href="https://api.whatsapp.com/send?phone=+5534998378252&text=Oi%20pessoal%20da%20Medfy.%20Podem%20me%20ajudar%20com%20um%20serviço?">Saiba mais</a>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div className="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-gray-200 rounded-lg">
                  <TrendingUp/>
                </div>
                <h4 className="text-xl text-gray-900 font-semibold mb-3">Marketing</h4>
                <p className="text-gray-500 mb-6 font-regular text-sm">Gestão e crescimento dos seus perfis nas redes sociais.</p>
                <a className="px-8 py-3 border border-transparent font-medium rounded-lg text-white bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 md:py-2 text-md md:px-10 transform" target="_blank" href="https://api.whatsapp.com/send?phone=+5534998378252&text=Oi%20pessoal%20da%20Medfy.%20Podem%20me%20ajudar%20com%20um%20serviço?">Saiba mais</a>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div className="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-gray-200 rounded-lg">
                  <CheckSquare/>
                </div>
                <h4 className="text-xl text-gray-900 font-semibold mb-3">Consultoria</h4>
                <p className="text-gray-500 mb-6 font-regular text-sm">Aprenda com médicos certificados por Harvard.</p>
                <a className="px-8 py-3 border border-transparent font-medium rounded-lg text-white bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 md:py-2 text-md md:px-10 transform" target="_blank" href="https://api.whatsapp.com/send?phone=+5534998378252&text=Oi%20pessoal%20da%20Medfy.%20Podem%20me%20ajudar%20com%20um%20serviço?">Saiba mais</a>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div className="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-gray-200 rounded-lg">
                  <Mail/>
                </div>
                <h4 className="text-xl text-gray-900 font-semibold mb-3">Email @medfy.net</h4>
                <p className="text-gray-500 mb-6 font-regular text-sm">Tenha seu próprio email profissional @medfy.net</p>
                <a className="px-8 py-3 border border-transparent font-medium rounded-lg text-white bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 md:py-2 text-md md:px-10 transform" target="_blank" href="https://api.whatsapp.com/send?phone=+5534998378252&text=Oi%20pessoal%20da%20Medfy.%20Podem%20me%20ajudar%20com%20um%20serviço?">Saiba mais</a>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div className="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-gray-200 rounded-lg">
                  <Award/>
                </div>
                <h4 className="text-xl text-gray-900 font-semibold mb-3">Emissão de certificados</h4>
                <p className="text-gray-500 mb-6 font-regular text-sm">Emita certificados para seus alunos.</p>
                <a className="px-8 py-3 border border-transparent font-medium rounded-lg text-white bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 md:py-2 text-md md:px-10 transform" target="_blank" href="https://api.whatsapp.com/send?phone=+5534998378252&text=Oi%20pessoal%20da%20Medfy.%20Podem%20me%20ajudar%20com%20um%20serviço?">Saiba mais</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
}
