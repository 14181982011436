import {UserX} from "react-feather";

export default function NoPurchase() {
  return (
    <div className="flex flex-row text-center items-center justify-center w-5/12 mx-auto my-32">
      <UserX className="text-6xl mr-6 mb-3 text-gray-300" />
      <h1 className="text-2xl ">Nenhum aluno por enquanto</h1>
    </div>
  );
}
