import React from "react";
import StudentLayout from "src/layouts/StudentLayout";
import CommunityChat from "src/components/CommunityChat";

export default function StudentMessages() {

  return (
    <StudentLayout>
      <section class="py-8">
        <CommunityChat/>       
      </section>    
    </StudentLayout>
  );
}
