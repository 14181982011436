import "src/editor.css";

export default function EbookAd({ ebook, description }) {
  return (
    <section className="py-4 pb-16 px-4 mx-4 md:px-8 border border-gray-200 rounded-lg bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-md md:max-w-none mx-auto">
          <div className="flex flex-wrap mt-10 -mx-4">
            <div className="w-full md:w-1/3 mb-12 md:mb-0 px-4">
              <div className="max-w-3xl">
                <div className="lg:flex h-full">
                  <div>
                    <img className="block rounded-lg h-full w-full max-w-2xl border border-gray-200" src={ebook?.logo} alt="Imagem principal do Ebook"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/3 px-4">
              <div className="p-8 h-full border border-gray-200 rounded-lg">
                <div className="pb-8 border-b border-gray-200">
                  <h1 className="font-heading font-semibold text-2xl text-gray-900 mb-3">{ebook?.title || "Título do material"}</h1>
                  <div className="my-8">
                    <div className="text-gray-500" dangerouslySetInnerHTML={{ __html: description }}></div>
                  </div>
                  <div className="inline-flex items-end">
                    <h4 className="text-2xl font-semibold text-gray-900 mr-4">R$ {ebook?.price || "0000"}</h4>
                  </div>
                </div>
                <div className="pt-8 pb-8 border-b border-blueGray-800">
                  <div className="max-w-xs xl:pr-20">
                    <span className="block text-gray-900 font-semibold mb-4">Detalhes</span>
                    <div className="flex mb-2">
                      <span className="text-gray-900 font-semibold">Categoria</span>
                      <span className="ml-auto text-sm text-gray-400">{ebook.category || "Neurologia"}</span>
                    </div>
                    <div className="flex mb-2">
                      <span className="text-gray-900 font-semibold">Nível do material</span>
                      <span className="ml-auto text-sm text-gray-400">{ebook.level || "Residência"}</span>
                    </div>
                    <div className="flex mb-2">
                      <span className="text-gray-900 font-semibold">Capítulos</span>
                      <span className="ml-auto text-sm text-gray-400">{ebook.chapters || "0"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
