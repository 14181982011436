import { useState, useEffect } from "react";
import { signInWithEmailAndPassword, getAuth } from "@firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Link, useHistory } from "react-router-dom";
import GoogleIcon from "../../images/google.svg"
import InputField from "src/components/InputField";
import { useRole } from "src/hooks/useRole";
import { Eye, EyeOff } from "react-feather";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const role = useRole();
  const history = useHistory();

  useEffect(() => {
    if (role === "student") {
      history.push("/purchases");
    } else if (role === "instructor") {
      history.push("/admin");
    }
  }, [role]);

  async function handleGoogleSignIn() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
  
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.log(error);
      setErrorMessage("Ocorreu um erro. Por favor, tente novamente mais tarde.");
    }
  }  

  function login() {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {})
      .catch((error) => {
        setErrorMessage("Ocorreu um erro. Por favor, cheque seu email e senha.");
      });
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <section className="py-10 h-screen overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="pt-16 pb-16 mx-auto bg-white rounded-3xl md:max-w-2xl border border-gray-200 px-6 md:px-0">
          <div className="md:max-w-md mx-auto">
            <div className="mb-10 text-center">
              <h2 className="font-heading mb-4 text-4xl tracking-tight text-gray-900 font-semibold">Acesse a plataforma</h2>
            </div>
            <button
                className="w-full flex flex-row items-center justify-center mt-12 px-8 py-3 text-md text-center text-gray-900 font-medium bg-white border border-gray-200 transition duration-200 ease-in hover:bg-gray-100 focus:ring-4 focus:ring-red-200 rounded-lg"
                onClick={handleGoogleSignIn}
              >
              <img src={GoogleIcon} className="mr-4 w-6" />
              Entrar com o Google
            </button>
            <div className="flex flex-row mt-4 mb-6">
              <div className="w-1/3 border-b border-gray-300"></div>
              <div className="w-1/3 text-gray-500 text-center">ou</div>
              <div className="w-1/3 border-b border-gray-300"></div>
            </div>
            <div>
              <InputField
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="seuemail@email.com"
              />
              <div className="relative">
                <InputField
                  type={passwordVisible ? "text" : "password"}
                  label="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="*********"
                />
                <button
                  className="absolute top-9 right-2 focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <div className="p-2 bg-gray-100 rounded-lg"><EyeOff className="h-5 w-5 text-gray-700"/></div> : <div className="p-2 bg-gray-100 rounded-lg"><Eye className="h-5 w-5 text-gray-700"/></div>}
                </button>
              </div>
            </div>

            <p className="text-xs text-center mt-2 mb-6 text-red-500">
              {errorMessage}
            </p>

            <button
              className="w-full block px-8 py-3.5 text-md text-center text-white font-medium bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 rounded-lg"
              onClick={() => login()}
            >
              Entrar na minha conta
            </button>
            
            <div className="w-full pt-6">
              <p className="text-gray-500 text-center text-sm font-regular"><span>Esqueceu sua senha? </span><Link to="/sign-in/reset-password" className="text-gray-800 hover:text-gray-900 font-regular">Recuperar minha senha</Link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
