import { Disclosure } from "@headlessui/react";
import logo_black from "../images/logo_black.svg";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import { useAuthState } from "src/firebase";
import { Plus } from "react-feather";
import ProfileMenu from "./ProfileMenu";
import { useRole } from "src/hooks/useRole";

export function Navbar() {
  const { user } = useAuthState();
  const role = useRole();

  return (
    <Disclosure as="nav" className="bg-gray-950 w-full md:block top-0 left-0 z-10">
      {({ open }) => (
        <section className="bg-white">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between py-3">
              <div className="w-auto">
                <div className="flex flex-wrap items-center">
                  <a className="flex pt-3" href="https://www.medfy.net">
                    <img className="h-6 -mt-2" src={logo_black} alt="Logo" />
                  </a>
                </div>
              </div>
              <div className="w-auto">
                <div className="flex flex-wrap items-center">
                  <div className="w-auto hidden lg:block">
                    <ul className="flex items-center justify-center">
                      <li className="mr-9">
                        <Link
                          to="/instructors"
                          className="inline-block font-regular text-gray-950 hover:text-gray-600 text-md transition duration-200 ease-in"
                        >
                          Instrutores
                        </Link>
                      </li>
                      <li className="mr-9">
                        <Link
                          to="/courses"
                          className="inline-block font-regular text-gray-950 hover:text-gray-600 text-md transition duration-200 ease-in"
                        >
                          Cursos
                        </Link>
                      </li>
                      <li className="mr-9">
                        <a
                          href="https://www.medfy.net"
                          className="inline-block font-regular text-gray-950 hover:text-gray-600 text-md transition duration-200 ease-in"
                        >
                          Para médicos
                        </a>
                      </li>
                      <li>
                        <Link
                          to="/faq"
                          className="inline-block font-regular text-gray-950 hover:text-gray-600 text-md transition duration-200 ease-in"
                        >
                          Perguntas frequentes
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-auto">
                <div className="flex flex-wrap items-center">
                  <div className="flex">
                    <MobileMenu />
                    {user ? (
                      <>
                        {role === "instructor" ? (
                          <Link
                            to="/products"
                            className="hidden flex-row md:flex h-full px-6 py-2 mr-4 mt-0.5 text-center text-white font-medium bg-blue-600 transition duration-200 ease-in hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-lg text-md border border-blue-200"
                          >
                            <Plus className="mr-1 w-5 h-5 pt-1" />
                            Crie um produto
                          </Link>
                        ) : (
                          ""
                        )}

                        <ProfileMenu role={role} />
                      </>
                    ) : (
                      <>
                        <div className="w-auto hidden md:block">
                          <div className="flex flex-wrap -m-2">
                            <div className="w-full md:w-auto p-2">
                              <Link
                                to="/sign-in"
                                className="block w-full px-6 py-2 text-center text-gray-950 font-medium transition duration-200 ease-in hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 focus:bg-gray-300 rounded-lg text-md border border-gray-200"
                              >
                                Entrar
                              </Link>
                            </div>
                            <div className="w-full md:w-auto p-2">
                              <Link
                                to="/sign-up"
                                className="block w-full px-6 py-2 text-center text-white font-medium bg-blue-600 transition duration-200 ease-in hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 rounded-lg text-md border border-blue-200"
                              >
                                Cadastre-se
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Disclosure>
  );
}
