import InputField from "src/components/InputField";
import CourseAd from "src/components/CourseAd";
import db, { useAuthState } from "src/firebase";
import ReactQuill from "react-quill";
import { useCallback, useEffect, useState } from "react";
import {
  addDoc,
  doc,
  setDoc,
  collection,
  Timestamp,
} from "@firebase/firestore";

import { useHistory, Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import {Eye, Edit} from "react-feather";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["semibold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

function WaitingBtn() {
  return (
    <div className="cursor-not-allowed transform ease-in duration-100  flex ml-2 mr-2 items-center font-semibold text-md justify-center px-8 py-3 bg-gray-300 rounded-lg text-gray-900">
      Aguardando respostas
    </div>
  );
}

export default function CourseEditor({ courseToEdit, id }) {
  let history = useHistory();
  const [editing, setEditing] = useState(true);
  const { user } = useAuthState();

  const [course, setCourse] = useState(
    courseToEdit || {
      instructor: user.displayName,
      instructorId: user.uid,
      logo: user.photoURL,
      title: "",
      category: "",
      price: "",
      level: "",
      modules: "",
      description: "",
      status: "Disponível",
      time: Timestamp.now(),
    }
  );

  const [description, setDescription] = useState(course?.description);

  useEffect(() => {
    setCourse({ ...course, description: description });
  }, [description]);

  let isComplete =
    course.title.length > 0 &&
    course.category.length > 0 &&
    course.price.length > 0 &&
    course.level.length > 0 &&
    course.modules.length > 0 &&
    course.description.length > 0;

  const addToDatabase = useCallback(async () => {
    if (courseToEdit && id) {
      await setDoc(doc(db, "courses", id), course);
    } else {
      await addDoc(collection(db, "courses"), course);
    }
    history.push("/admin");
  }, [course, user, db, id]);

  return (
    <div className="w-1/2 mx-auto mt-16  grid">
      <div className=" p-1 rounded-lg w-64 justify-self-end flex flex-row">
        <button
          className={`${
            editing ? "bg-white text-gray-900" : " text-gray-400"
          } px-5 py-2 bg-white rounded-lg mr-1 font-medium flex flex-row`}
          onClick={() => setEditing(true)}
        >
          <Edit
            className={`${
              editing ? "text-gray-900" : "text-gray-400"
            } mr-2.5 text-sm`}
          />
          Editar
        </button>
        <button
          className={`${
            !editing ? "bg-white text-gray-900" : " text-gray-400"
          } px-4 py-2 bg-white rounded-lg font-medium flex flex-row`}
          onClick={() => setEditing(false)}
        >
          <Eye
            className={`${
              !editing ? "text-gray-900" : "text-gray-400"
            } mr-2.5 text-sm`}
          />
          Visualizar
        </button>
      </div>

      {editing ? (
        <>
          <div className="grid grid-cols-4 gap-4 mt-6">
            <InputField
              className="col-span-2"
              type="text"
              label="Preço em R$"
              placeholder="2500"
              value={course.price}
              onChange={(e) => {
                setCourse({
                  ...course,
                  price: e.target.value,
                });
              }}
            />
            <InputField
              className="col-span-2"
              label="Nível de Ensino"
              type="text"
              placeholder="Residência"
              value={course.level}
              onChange={(e) => {
                setCourse({
                  ...course,
                  level: e.target.value,
                });
              }}
            />
          </div>
          <div className="grid grid-cols-4 gap-4 mt-1">
            <InputField
              className="col-span-2"
              type="text"
              label="Módulos"
              value={course.modules}
              onChange={(e) => {
                setCourse({
                  ...course,
                  modules: e.target.value,
                });
              }}
              placeholder="10"
            />

            <InputField
              className="col-span-2"
              type="text"
              label="Categoria"
              value={course.category}
              onChange={(e) => {
                setCourse({
                  ...course,
                  category: e.target.value,
                });
              }}
              placeholder="Neurologia"
            />
          </div>

          <label className="block text-sm font-semibold text-gray-950 mt-6 mb-2">
            Descrição do curso
          </label>

          <ReactQuill
            modules={modules}
            theme="snow"
            value={description}
            onChange={setDescription}
            placeholder="Descreva seu curso aqui..."
          />
        </>
      ) : (
        <CourseAd course={course} description={course.description} />
      )}

      <div className="flex items-center pt-6 mt-12">
        {isComplete ? (
          <button
            className="text-center transform  cursor-pointer font-semibold text-md px-8 py-3 bg-gray-900 rounded-lg text-white"
            onClick={() => addToDatabase()}
          >
            Salvar alterações
          </button>
        ) : (
          <WaitingBtn />
        )}

        <Link
          to="/admin"
          className="ml-2 font-semibold mr-2 cursor-pointer border-b-2 border-gray-900  px-8 py-3 rounded-lg border-none"
        >
          Cancelar
        </Link>
      </div>
    </div>
  );
}
