import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "src/components/Select";
import Search from "src/components/SearchInput";
import NoPurchase from "src/components/emptyStates/NoPurchase";

const times = ["Mais recentes", "Menos recentes"];
const th = ["Aluno", "Estuda desde", "", ""];

export default function StudentTable({ purchases }) {
  const history = useHistory();
  let currentDate = new Date();
  const [selectedTime, setSelectedTime] = useState(times[0]);
  let [displayedPurchases, setDisplayedPurchases] = useState([]);
  let url = window.location.href;
  let course = url.split("/")[url.split("/").length - 1];

  useEffect(() => {
    setDisplayedPurchases(purchases);
  }, []);

  useEffect(() => {
    setDisplayedPurchases(purchases);
  }, [purchases]);

  const handleSearch = (data) => {
    setDisplayedPurchases(data);
  };

  function handleClick(id) {
    if (course === "talent-pool") {
      history.push(`/talent-pool/${id}`);
    } else {
      history.push(`/admin/${url.split("/")[url.split("/").length - 1]}/${id}`);
    }
  }

  function calculateDays(date) {
    let daysAgo = Math.floor((currentDate - date) / (1000 * 3600 * 24));

    if (daysAgo < 1) {
      return "Hoje";
    } else if (daysAgo < 2) return daysAgo + " dia atrás";
    else return daysAgo + " dias atrás";
  }

  function time(input) {
    setSelectedTime(input);

    if (input === "Menos recentes") {
      displayedPurchases.sort(
        (a, b) => a.data().time.toMillis() - b.data().time.toMillis()
      );
    } else {
      displayedPurchases.sort(
        (a, b) => b.data().time.toMillis() - a.data().time.toMillis()
      );
    }
  }

  if (purchases.length === 0) {
    return <NoPurchase />;
  }

  return (
    <>
      <div className="mt-6 flex items-center bg-white p-8 gap-3">
        <Search list={purchases} handleSearch={handleSearch} />

        <div className="w-48">
          <Select
            selected={selectedTime}
            statuses={times}
            changeStatus={time}
          />
        </div>
      </div>

      <div className="mt-6 overflow-x-auto bg-white border border-gray-200 rounded-xl">
        <div className="py-2 align-middle inline-block min-w-full">
          <table className="min-w-full divide-y divide-gray-300 z-0">
            <thead>
              <tr>
                {th.map((t) => (
                  <th className="px-6 py-3 text-left text-xs text-gray-900 uppercase tracking-wider leading-tight font-semibold">
                    {t}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 divide-dashed">
              {displayedPurchases.map((purchase) => (
                <tr key={purchase.id} className="transition duration-200 ease-in hover:">
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                    onClick={() => handleClick(purchase.id)}
                  >
                    <div className="h-10 w-10 border border-gray-500 rounded-lg mr-3 text-center pt-1 text-lg capitalize float-left ">
                      {purchase.data().student.name[0]}
                    </div>

                    <div className="float-left">
                      <div className="text-sm font-medium text-gray-900">
                        {purchase.data().student.name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {purchase.data().student.email}{" "}
                      </div>
                    </div>
                  </td>

                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                    onClick={() => handleClick(purchase.id)}
                  >
                    {calculateDays(purchase.data().time.toDate())}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
