import {Search} from "react-feather";

export default function SearchInput({ list, handleSearch }) {
  function search(input) {
    const filter = input.toUpperCase();

    var tmp = [];
    list.forEach((item) => {
      if (item.data().student.name.toUpperCase().indexOf(filter) > -1) {
        tmp.push(item);
      }
    });

    handleSearch(tmp);
  }

  return (
    <div className="relative w-full">
      <Search
        className="absolute w-5 h-5 top-3.5 left-4 text-gray-600 "
      />

      <input
        className="h-12 w-2/3 text-lg block text-gray-700 rounded-lg border border-grey-light p-3 focus:ring-gray-900 focus:border-gray-900 py-3 pl-12 px-4 leading-tight focus:outline-none"
        placeholder="Buscar aluno..."
        onChange={(e) => search(e.target.value)}
      />
    </div>
  );
}
