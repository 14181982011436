import { useEffect } from "react";

function TermsOfService() {
  return (
    <div className=" text-xl">
      <div className="md:py-16 py-16 max-w-4xl mx-auto px-3 text-left">
      <h2 className="mb-4 font-sans text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl mt-8 text-center sm:leading-none">
        Termos de serviço
      </h2>
      <p className="text-sm mt-8 text-left text-gray-900 md:text-md">
        1. ESTES TERMOS E CONDIÇÕES DE USO ESTABELECEM UM RELACIONAMENTO COMERCIAL
        <br/>
        <br/>
        Estes Termos e Condições de Uso ("Termos") estabelecem regras e condições para todos os usuários, incluindo usuários individuais ("Candidato"); usuários legais, pessoas jurídicas e seus representantes ("Empresa"); e grupos referidos apenas como "Usuário ou uso de empresas de internet que acessam ou utilizam vagas de emprego" ("Plataforma Medfy" ou "Plataforma Medfy"), pela ASTER LTDA (Medfy) no CNPJ nº 43.298.443/0001-13 ("Medfy").
        1.1. Estes Termos se aplicam e são usados ​​pelo Usuário da Plataforma Medfy (os "Serviços") conforme desenvolvido pela Medfy.
        1.2. Ao utilizar, de qualquer forma, os Serviços, o Usuário concorda com estes Termos, declarando estar ciente de que, caso não concorde com o teor deste documento, não deverá acessar nem utilizar os Serviços. 
        1.3. Por meio de seu representante legal devidamente constituído ou de um procurador a quem tenham sido outorgados tais direitos, o Usuário declara que aderiu a estes Termos.
        1.4. Estes Termos podem ser alterados a qualquer momento sem a permissão do Usuário. Como resultado, o Usuário deve revisar os Termos regularmente para garantir que está ciente de quaisquer alterações no conteúdo. Os Termos estarão disponíveis em https://Medfy.to/terms ("Site") e entrarão em vigor quando os dados sobre o uso da Plataforma pelo Usuário forem publicados.
        1.5. A Medfy poderá oferecer ou acessar sua Plataforma, os Serviços, ou qualquer parte deles, a qualquer momento e por qualquer motivo, sem prévio aviso, unicamente para cumprir quaisquer compromissos que o Usuário já tenha firmado.
        1.6. Quaisquer dúvidas, preocupações ou outras comunicações sobre estes Termos ou a operação e/ou uso da Plataforma Medfy devem ser direcionadas para as informações de contato fornecidas na Plataforma ou no Site.
        <br/>
        <br/>
        2. OBJETIVO E DESCRIÇÃO DO SERVIÇO
        <br/>
        <br/>
        A Plataforma Medfy é um aplicativo de internet que pretende mudar a experiência de recrutamento e seleção entre candidatos e empresas, vinculando talentos a vagas conectadas ao mercado digital por meio de um procedimento rápido e transparente. A Medfy fornecerá os seguintes serviços por meio da Plataforma:
        I – a favor do Candidato: cadastro e acesso às Cursos oferecidas pela Companhia e demais empresas cadastradas na Plataforma, bem como cadastro para concorrer a quaisquer Cursos; e II – a favor da Companhia: oportunizar a oferta de Cursos, a publicação de conteúdo relacionado e o engajamento com Candidatos; e visualização das candidaturas e perfis do Candidato e de outros candidatos registados na Plataforma.
        <br/>
        <br/>
        3. REGISTRO DO CANDIDATO E UTILIZAÇÃO DOS SERVIÇOS
        <br/>
        <br/>
        O Candidato poderá visitar a Plataforma Medfy sem pagamento; no entanto, para se candidatar às Cursos de Interesse, ele deverá primeiro se cadastrar, o que resultará na criação de uma conta de acesso (“Conta”) que lhe permitirá utilizar os recursos da Medfy.
        3.1. O Candidato é o único responsável por concluir o processo de cadastro na Plataforma Medfy para abertura da Conta, bem como por selecionar uma senha forte e segura e mantê-la em sigilo, não podendo a Medfy ser responsabilizada por quaisquer atos, fatos ou consequências resultantes do compartilhamento, uso inadequado ou descuido do Candidato.
        3.2. O Candidato deve preencher todos os campos obrigatórios com informações precisas e factuais e se comprometer a atualizar as informações cadastrais sempre que houver alterações. O Candidato é o único responsável pela veracidade das informações fornecidas durante o registro na Plataforma.
        3.3. O Candidato habilita a Medfy a obter todos os documentos necessários para comprovar a integridade dos dados cadastrais fornecidos, bem como verificar os dados por qualquer outro meio. O fato de a Medfy não solicitar definitivamente tais documentos ou verificar a exatidão dos dados fornecidos não exime o Candidato das obrigações descritas neste artigo.
        3.4. A Medfy poderá, a seu critério e de acordo com sua Política de Privacidade vigente, solicitar ao Candidato outros documentos e/ou informações que julgar necessários para a prestação dos Serviços a qualquer momento durante a vigência destes Termos.
        3.5. O Candidato inscrito deve ter capacidade jurídica para a prática de atos civis, ou seja, ser maior de 18 (dezoito) anos ou emancipado, e não estar sujeito a nenhuma das causas debilitantes previstas na lei civil, nem a tutela. Judiciário. O responsável legal e/ou curador responderá pelas atividades realizadas em nome do Candidato caso este seja menor de 18 (dezoito) anos, incapaz ou sujeito à curadoria.
        3.6. O Candidato também deve ser capaz de exercer as responsabilidades especificadas pela Companhia na oferta de vaga, inclusive aquelas que exijam registro em conselho profissional específico, e ser única e pessoalmente responsável por quaisquer declarações falsas feitas a esse respeito.
        3.7. Ao se candidatar a uma vaga oferecida na Plataforma Medfy, o Candidato confirma que seu perfil, formação e experiência estão de acordo com os exigidos pela Companhia, por sua própria e pessoal responsabilidade, sem garantia da Medfy.
        3.8. Em casos de descumprimento das regras e políticas estabelecidas nestes Termos, cadastros duplicados, imprecisão nas informações fornecidas pelo Candidato ou prática de ilegalidade no uso da Plataforma Medfy, a Medfy poderá cancelar a Conta do Candidato ou recusar-se a registro, mesmo que previamente aceito.
        3.9. O Candidato poderá remover sua Conta da Plataforma Medfy a qualquer momento, caso em que seu conteúdo não poderá mais ser visualizado pelas firmas ali registradas, não sendo a Medfy responsável por qualquer perda de tal conteúdo após a exclusão.
        3.10. O Candidato poderá avaliar a qualidade da oferta de cada Empresa na Plataforma, adicionando comentários, atribuindo notas às Cursos, Instrutores e até mesmo aos conteúdos divulgados por elas, com base no ranking da Medfy, e concorda em fazê-lo de forma idônea e de acordo com as disposições destes Termos, e será responsável por qualquer dano causado à Empresa a esse respeito.
        <br/>
        <br/>
        4. REGISTRO DA SOCIEDADE E USO DOS SERVIÇOS
        <br/>
        <br/>
        A Empresa poderá acessar a Plataforma Medfy desde que aceite as condições comerciais previstas nestes Termos e realize, por meio de pessoa com poderes necessários para tanto, um cadastro prévio a partir do qual será criada uma conta de acesso (“Conta”) que permitirá usufruir das funcionalidades disponibilizadas pela Medfy.
        4.1. O cadastro para o estabelecimento da Conta poderá ser realizado diretamente na Plataforma Medfy por um representante da Empresa, ou mediante preenchimento de formulário fora da Plataforma, cujo conteúdo a Medfy terá conhecimento para agilizar a criação do Conta.
        4.2. Em qualquer caso, a Empresa é totalmente responsável por definir a senha de acesso à Plataforma Medfy no primeiro acesso à sua Conta, devendo escolher uma senha forte e segura, bem como mantê-la em sigilo, não podendo a Medfy ser responsabilizada por quaisquer atos , fatos ou consequências decorrentes do compartilhamento, uso inadequado e/ou descuido da Empresa e/ou de seus agentes e representantes legais.
        4.3. Todos os campos imprescindíveis para realizar o cadastro devem ser preenchidos com informações corretas e verdadeiras, e a Empresa se compromete a atualizar as informações cadastrais sempre que houver alguma alteração, sob sua responsabilidade.
        4.4. A Companhia poderá cadastrar diferentes usuários para acesso simultâneo à Conta de sua titularidade, de acordo com o Plano pactuado, sendo responsável pelos dados informados e qualquer conduta vinculada aos usuários no uso da Plataforma.
        4.5. A Companhia autoriza a Medfy a solicitar todos os documentos necessários para confirmar a autenticidade dos dados cadastrais fornecidos, bem como realizar qualquer outra verificação. O fato de a Medfy não solicitar definitivamente tais documentos ou verificar a exatidão dos dados apresentados não exime a Empresa de suas obrigações nos termos deste artigo.
        4.6. A Medfy poderá, a seu critério e de acordo com sua Política de Privacidade vigente, solicitar à Empresa outros documentos e/ou informações que julgar necessários para a prestação dos Serviços a qualquer momento durante a vigência destes Termos.
        4.7. A Empresa declara que somente divulgará vagas reais e existentes na Plataforma Medfy, concordando em cumprir todos os termos oferecidos e assumindo plena e irrestritamente, sem qualquer responsabilidade perante a Medfy, qualquer responsabilidade decorrente de informações falsas ou inconsistentes sobre a vaga, bem como como danos causados ​​a candidatos que possam ter sido prejudicados.
        4.7.1 A Companhia autoriza a Medfy a utilizar quaisquer informações contidas no cadastro de vagas para fins de divulgação e prestação do serviço de forma correta.
        4.7.2 A Medfy pode rejeitar qualquer vaga apresentada pela empresa que não atenda aos padrões, salário ou benefícios da empresa.
        4.8. Em casos de descumprimento das regras e políticas estabelecidas nestes Termos, cadastros duplicados, imprecisão nas informações fornecidas pela Empresa, ou prática de ilegalidade no uso da Plataforma Medfy, a Medfy poderá cancelar a Conta da Empresa ou recusar seu inscrição, mesmo que previamente aceita.
        4.9. A Empresa poderá desativar sua Conta na Plataforma Medfy a qualquer momento, caso em que seu conteúdo não poderá mais ser visualizado pelos candidatos ali inscritos, a critério da Medfy, não sendo a Medfy responsável pela perda de tal conteúdo após a exclusão. Nesse caso, serão devidos os valores devidos pelo período de licença contratado, não se responsabilizando a Medfy pelo seu reembolso. O cancelamento pode ocorrer antecipadamente, em troca do pagamento integral do período de licença negociado; ou no final do prazo, em troca da decisão da Companhia de não renovar a licença.
        <br/>
        <br/>
        5. OBRIGAÇÕES E CONDUTA DO USUÁRIO
        <br/>
        <br/>
        O Usuário deverá cumprir todas as obrigações estabelecidas nestes Termos ao utilizar a Plataforma.
        5.1. O Usuário declara e concorda em utilizar os Serviços e navegar na Plataforma Medfy apenas por motivos legítimos, para cumprir estes Termos e toda a legislação nacional aplicável, e abster-se de apoiar ações criminosas e/ou ilícitas em geral.
        5.1.1. O Usuário deve tratar todas as pessoas com as quais entrar em contato por meio da Plataforma Medfy com respeito e cortesia. Como resultado, o Usuário deve abster-se de publicar ou divulgar conteúdo difamatório, calunioso, injurioso, violento, obsceno, racista, preconceituoso, pornográfico, imoral, ilícito que contenha nudez ao utilizar os Serviços e ao navegar na Plataforma Medfy. A Medfy pode, mas não está obrigada a, analisar, monitorar ou remover tal conteúdo a qualquer momento se for considerado ofensivo.
        5.1.2. O Utilizador é pessoalmente responsável pelos atos cometidos durante a utilização da Plataforma que sejam difamatórios, discriminatórios, ofensivos, obscenos, injuriosos, injuriosos, violentos, constituam ameaças ou assédio de qualquer natureza, ou infrinjam os direitos de terceiros de qualquer forma, e que são cometidos no contexto descrito acima. A Medfy não se responsabiliza por quaisquer danos causados, associados ou resultantes das ações do Usuário, seus agentes, representantes ou curadores.
        5.2. O Usuário é responsável por fornecer acesso à Internet e outros serviços de terceiros que possam ser necessários para o uso dos Serviços, bem como por cobrir todos os custos associados a esses acessos.
        5.2.1. A Medfy não se responsabiliza, em hipótese alguma, por falhas na conexão do Usuário ou sua conexão com serviços de terceiros que ele venha a utilizar.
        5.2.2. A Medfy não se responsabiliza por quaisquer danos incorridos pelo Usuário em decorrência de falhas de equipamentos ou falhas de operação da rede, nem por possíveis bloqueios de acesso ou obstáculos operacionais causados ​​por configurações de acesso e/ou permissões de acesso desatualizadas. O Usuário é responsável por manter esses dados atualizados conforme necessário.
        5.2.3. A Medfy alerta que o uso da internet e comunicações eletrônicas expõe a Plataforma a interferências, mau funcionamento e/ou atrasos. O Usuário confirma que está ciente dessas possibilidades e que esses fatores podem alterar de alguma forma a eficiência da utilização dos Serviços, sem ter direito a qualquer compensação por quaisquer perdas de qualquer natureza que venha a sofrer. Nesses casos, a Medfy retornará a Plataforma à operação normal o mais rápido possível.
        5.3. O Utilizador é responsável por manter a confidencialidade dos dados de acesso à sua Conta, os quais não devem ser partilhados com terceiros. A Medfy não tem envolvimento com essas informações e, em caso de perda, o Usuário deverá solicitar imediatamente sua alteração para evitar perdas.
        5.3.1. O Usuário entende que todos os acessos aos seus dados são lidos como sendo feitos por ele mesmo, e que todas as ações realizadas por e em sua Conta são de sua exclusiva responsabilidade.
        5.3.2. A Medfy não será responsável por qualquer dano, responsabilidade ou perda resultante do uso ou incapacidade de uso dos Serviços pelo Usuário.
        5.4. O Usuário reconhece que suas ações serão consideradas ilegais se, ao utilizar os Serviços ou navegar na Plataforma Medfy: I violar qualquer regra estabelecida nestes Termos; (ii) cometa infrações penais e/ou contravenções; (iii) ele se envolver em quaisquer crimes cibernéticos, como invasão de sistemas e hardware de terceiros, sabotagem, alteração de informações, reversão de criptografia, espionagem,
        5.4.1. Em caso de: uso indevido da plataforma; (ii) violação pelo Usuário das regras estabelecidas nestes Termos e/ou legislação brasileira; (iii) imposição pelo Usuário de obstáculo à verificação de sua identidade, quando necessário para a utilização dos Serviços; (iv) inadimplência da Companhia; e (v) interrupção pela Companhia, a Medfy poderá se recusar a oferecer os Serviços ao Usuário, ou mesmo impedir seu acesso à Plataforma Medfy
        5.4.2. Caso a Medfy seja responsável por ato imputável ao Usuário, este deverá tomar todas as medidas necessárias para retirá-lo de qualquer processo judicial em curso. Além disso, a Medfy terá direito ao reembolso de todas as despesas, incluindo despesas e honorários advocatícios.
        5.4.3. Havendo suspeita razoável de que o Usuário esteja praticando comportamento ilícito, a Medfy poderá, além das ações já indicadas, denunciar o incidente às autoridades competentes para investigação.
        <br/>
        <br/>
        6. EXCLUSÃO DE RESPONSABILIDADE E GARANTIA
        <br/>
        <br/>
        As Partes estão cientes de que os Serviços correspondem apenas ao que está definido nestes Termos, e que seu escopo deve ser entendido de forma restrita, excluindo quaisquer coisas não especificamente mencionadas neste contrato. Em razão disso, fica expressamente declarado que: I – a Medfy não garante ao Candidato a disponibilização de Cursos de seu interesse na Plataforma; II – A Medfy não garante à Companhia a existência de interessados ​​nas Cursos, nem a aptidão ou contratação de Candidatos que venham a manifestar interesse em tais Cursos; III – A Medfy não garante a permanência do Candidato eventualmente contratado pela Companhia por tempo mínimo de trabalho;
        <br/>
        <br/>
        7. TERMOS DE PAGAMENTO E CONDIÇÕES COMERCIAIS
        <br/>
        <br/>
        A Companhia pagará à Medfy o valor correspondente ao Plano de Uso selecionado no ato da contratação (“Plano”), de acordo com as condições comerciais vigentes e divulgadas pela Medfy no Site, na própria Plataforma, ou por meio de proposta comercial.
        7.1. O pagamento dos Serviços deve ser feito antecipadamente, e o acesso à Plataforma será concedido somente após o pagamento da Empresa.
        7.2. Caso a Empresa deixe de efetuar o pagamento do valor do Plano selecionado antes do término do período de licença contratado, a Medfy poderá suspender o acesso da Empresa à Plataforma.
        7.3. A Medfy reserva-se o direito de reajustar o valor do Plano a qualquer momento. No entanto, a alteração descrita acima só entrará em vigor após o término do período de licença negociado, e a Companhia será responsável por decidir se estende ou não a licença sob os novos termos. Se a Empresa não quiser continuar a prestar o serviço, basta que a Empresa se recuse a pagar; no entanto, se o pagamento for efetuado, estes Termos são renovados por outra ocasião.
        7.4. A Empresa poderá efetuar os pagamentos devidos à Medfy utilizando qualquer uma das formas indicadas no Site, na Plataforma ou através de proposta comercial.
        7.5. A Medfy poderá permitir que a Empresa utilize a Plataforma gratuitamente por tempo limitado ("Período de Avaliação"), após o qual o acesso será limitado a quem adquiriu um Plano. Durante o Período de Avaliação, a Medfy pode optar por não disponibilizar determinados recursos da Plataforma, sem objeção da Empresa.
        7.6. Os valores cobrados pela Medfy incluem todas as taxas fiscais, trabalhistas e previdenciárias associadas aos Serviços; a Companhia não deve taxas adicionais. A fatura correspondente será emitida em até 30 (trinta) dias após a confirmação do pagamento.
        7.7. A Empresa declara estar ciente de que, salvo indicação em contrário, quaisquer ofertas gratuitas de recursos e módulos são apenas temporárias, e que a Medfy se reserva o direito de alterá-los, descontinuar ou cobrá-los a qualquer momento sem aviso prévio, e que é o único responsável para o dever de notificar antecipadamente a ação que tomará em relação aos itens gratuitos.
        <br/>
        <br/>
        8. PROPRIEDADE INTELECTUAL
        <br/>
        <br/>
        Todos os textos, imagens, fotos, ilustrações, ícones, layouts, designs, recursos e outros recursos visuais, bem como os sistemas, bancos de dados, redes, tecnologias, links e arquivos que permitem ao Usuário navegar no aplicativo e utilizar os Serviços , são de propriedade da Medfy e estão protegidos por leis e tratados internacionais de direitos autorais, marcas, patentes, modelos e desenhos industriais, sendo proibido seu uso pelo Usuário, a menos que seja obtida autorização prévia por escrito.
        8.1. Ao utilizar a Plataforma Medfy, o Usuário concorda em respeitar todos os direitos autorais e de propriedade intelectual da Medfy. O simples acesso do Usuário à Plataforma Medfy, Conta ou uso dos Serviços não confere ao Usuário quaisquer direitos de uso dos nomes, títulos, palavras, frases, marcas, patentes, criações intelectuais, fotos, vídeos ou símbolos que sejam, ou estavam, disponíveis neles.
        8.2. O Usuário está proibido de copiar, reproduzir ou espelhar o conteúdo e layout da Plataforma Medfy, bem como fazer engenharia reversa da tecnologia da Medfy ou dos Serviços em geral.
        8.3. O Usuário assume total responsabilidade pelo uso indevido dos elementos indicados nesta seção, ou seja, por qualquer violação civil ou criminal dos direitos de propriedade intelectual da Medfy.
        <br/>
        <br/>
        9. PROTEÇÃO DE DADOS E PRIVACIDADE
        <br/>
        <br/>
        A Medfy concorda em processar os dados pessoais do Usuário de acordo com a LGPD (Lei 13.709/2018), o Marco Civil da Internet (Lei 12.965/2014) e demais leis brasileiras relativas à proteção de dados pessoais, tudo nos termos do Política de Privacidade da Plataforma Medfy, que pode ser consultada em https://Medfy.to/privacy e cujo conteúdo o Utilizador declara conhecer sem reservas.
        <br/>
        <br/>
        10. REQUISITOS GERAIS
        <br/>
        <br/>
        10.1. Todas as comunicações entre o Usuário e a Medfy, inclusive as relativas à exclusão de sua Conta, à retirada dos serviços prestados e ao esclarecimento de dúvidas, deverão ser enviadas pelos meios especificados na Plataforma Medfy ou no Site, como as enviadas via outros meios podem não ser recebidos.
        10.2. A Medfy poderá ceder seus direitos sobre a Plataforma Medfy e sua posição nestes Termos a terceiros a qualquer momento, com os quais o Usuário desde já concorda, não tendo nada a que se opor.
        10.3. Caso a Medfy deixe de exigir o cumprimento tempestivo e completo de qualquer uma das obrigações decorrentes destes Termos, ou de exercer qualquer direito ou faculdade que lhe seja atribuída, tal fato será interpretado como mera tolerância, não como renúncia aos direitos e faculdades não exercidos. , nem como novação ou revogação de quaisquer cláusulas ou condições destes Termos, que permanecerão em pleno vigor para todos os efeitos.
        10.4 Caso qualquer disposição destes Termos seja considerada ilegal ou ineficaz por qualquer motivo, as demais cláusulas e condições permanecerão totalmente válidas e exequíveis, gerando plenas consequências como meio de alcançar a intenção das Partes.
        10.5. Estes Termos são regulamentados e interpretados apenas de acordo com a lei brasileira. Qualquer reclamação, desacordo ou problema decorrente ou relacionado a estes Termos, incluindo aqueles envolvendo sua legalidade, interpretação ou exigibilidade, será decidido na comarca de Minas Gerais/MG.
      </p>
      <hr className="w-full my-8 border-gray-200" />
      </div>
    </div>
  );
}

export default TermsOfService;
