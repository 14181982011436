import {
  useParams,
  useHistory,
} from "react-router-dom";
import { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "@firebase/firestore";
import db, { useAuthState } from "src/firebase";
import Comments from "../../components/Comments";
import StudentLayout from "src/layouts/StudentLayout";

export default function Watch() {
  const { courseId } = useParams();
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const [course, setCourse] = useState(null);
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const { user } = useAuthState();
  const completedVideosCount = progress ? progress.completedVideos.length : 0;

  useEffect(() => {
    const fetchCourseInfo = async () => {
      const courseDoc = await getDoc(doc(db, "courses", courseId));
      setCourse(courseDoc.data());
      const courseVideos = courseDoc.data().videos;
      setVideos(courseVideos);
      setCurrentVideo(courseVideos[0]);

      const progressDoc = await getDoc(
        doc(db, "students", user.uid, "progress", courseId)
      );
      if (progressDoc.exists()) {
        setProgress(progressDoc.data());
      } else {
        await setDoc(doc(db, "students", user.uid, "progress", courseId), {
          completedVideos: [],
        });
        setProgress({ completedVideos: [] });
      }
    };

    fetchCourseInfo();
  }, [courseId, user.uid]);

  const saveProgress = async (index) => {
    const completedVideo = {
      courseId: courseId,
      videoIndex: index,
    };
  
    const isCompleted = progress.completedVideos.some(
      (video) =>
        video.courseId === completedVideo.courseId &&
        video.videoIndex === completedVideo.videoIndex
    );
  
    const progressDocRef = doc(db, "students", user.uid, "progress", courseId);
  
    if (isCompleted) {
      const newCompletedVideos = progress.completedVideos.filter(
        (video) =>
          video.courseId !== completedVideo.courseId ||
          video.videoIndex !== completedVideo.videoIndex
      );
  
      await updateDoc(progressDocRef, {
        completedVideos: newCompletedVideos,
      });
  
      setProgress({
        ...progress,
        completedVideos: newCompletedVideos,
      });
    } else {
      await updateDoc(progressDocRef, {
        completedVideos: [...progress.completedVideos, completedVideo],
      });
  
      setProgress({
        ...progress,
        completedVideos: [...progress.completedVideos, completedVideo],
      });
    }
  };

  const handleVideoEnded = async () => {
    const index = videos.indexOf(currentVideo);
    if (index + 1 < videos.length) {
      setCurrentVideo(videos[index + 1]);
      setCurrentVideoIndex(index + 1);
    }
  };

  const checkMarkSvg = (

  <svg xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24">
    <path fill="#22c55e" d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z"/>
  </svg>
  );

  if (!course || !videos || !progress) {
    return (
      <div className="md:pt-16 h-screen overflow-hidden transition transform ease-in-out delay-500 bg-gray-900">

      </div>
    );
  }

  return (
    <StudentLayout>
        <section className="md:pt-16 overflow-hidden transition transform ease-in-out delay-500 bg-gray-800">
          <div className="container mx-auto md:px-4">
            <div className="mx-auto rounded-3xl md:max-w-6xl">
              <div className="md:max-w-6xl mx-auto flex flex-wrap md:flex-row flex-col">
                <div className="md:w-8/12">
                  {/* Video player component */}
                  <div>
                    <video
                      src={currentVideo.url}
                      controls
                      className="w-full md:rounded-l-lg md:border-8 md:border-gray-700"
                      onEnded={handleVideoEnded}
                    ></video>
                  </div>
                </div>
                <div className="md:w-4/12 container md:p-8 p-4 md:py-16 bg-gray-700 md:rounded-r-lg">
                  {/* List of other videos */}
                  <ul className="items-center justify-center">
                    <span className="text-white text-center text-lg font-semibold">
                      {course.title}
                    </span>
                    {/* Progress bar */}
                    <div className="mt-4">
                      <div className="bg-gray-800 h-4 w-full rounded-md">
                        <div
                          className="bg-blue-600 h-4 rounded-md"
                          style={{ width: `${(completedVideosCount / videos.length) * 100}%` }}
                        ></div>
                      </div>
                      <p className="text-left text-white text-sm mt-1">
                        {completedVideosCount} / {videos.length} Aulas completas
                      </p>
                    </div>
                    {videos.map((video, index) => {
                      const isCompleted = progress.completedVideos.some(
                        (completedVideo) =>
                          completedVideo.videoIndex === index &&
                          completedVideo.courseId === courseId
                      );

                      return (
                        <li
                          key={index}
                          onClick={() => {
                            setCurrentVideo(video);
                            setCurrentVideoIndex(index);
                          }}
                          className={`cursor-pointer text-sm font-normal p-2 rounded-lg mt-3 flex border border-gray-600 items-center text-white justify-between ${
                            currentVideoIndex === index ? "bg-gray-600 p-2 rounded-lg" : ""
                          }`}
                        >
                          {video.title}
                          <div className="flex flex-row">
                            {currentVideoIndex === index && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  saveProgress(index);
                                }}
                                className={`${
                                  isCompleted ? "bg-gray-500" : "bg-blue-600"
                                } text-white px-2 py-1 ml-2 rounded text-xs`}
                              >
                                {isCompleted ? "Incompleto" : "Completo"}
                              </button>
                            )}
                            {isCompleted && checkMarkSvg}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Comments />
    </StudentLayout>
  );
}
