import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import CourseCreator from "src/components/CourseCreator";

export default function AdminAddCourse() {
  return (
    <>
      <CourseCreator />
    </>
  );
}
