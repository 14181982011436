import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { doc, getDoc } from "@firebase/firestore";
import { Link } from "react-router-dom";
import db from "src/firebase";
import PurchaseStatus from "src/components/statuses/PurchaseStatus";
import { ChevronLeft } from "react-feather";
import Loader from "src/components/Loader";
import { useRole } from "src/hooks/useRole";
import AdminLayout from "src/layouts/AdminLayout";

function AdminPurchase() {
  let [purchase, setPurchase] = useState();
  let url = window.location.href;
  const role = useRole();
  let course = url.split("/")[url.split("/").length - 2];
  let id = url.split("/")[url.split("/").length - 1];

  const ref = doc(db, "purchases", id);

  let currentDate = new Date();

  function getPurchase() {
    getDoc(ref).then((doc) => setPurchase(doc.data()));
  }

  function calculateDays(date) {
    let daysAgo = Math.floor((currentDate - date) / (1000 * 3600 * 24));

    if (daysAgo < 1) {
      return "Hoje";
    } else if (daysAgo < 2) return daysAgo + " dia atrás";
    else return daysAgo + " dias atrás";
  }

  useEffect(() => {
    getPurchase();
  }, []);

  if (!purchase) {
    return <Loader />;
  }

  return (
    <AdminLayout>
      <div className="lg:pt-12 pt-8 pb-16  h-screen">
        <div className="w-10/12 mx-auto">
          <div className="flex justify-between">
            <Link
              to={
                url.split("/")[url.split("/").length - 2] == "talent-pool"
                  ? "/talent-pool"
                  : `/admin/${course}`
              }
              className="text-3xl flex flex-row font-medium text-center"
            >
               <ChevronLeft className="mr-3 h-5 w-5 mt-2" />
              {purchase?.student.name}
            </Link>
          </div>

          <div className="grid grid-cols-12 gap-6 mt-8">
            <div className="md:col-span-6 col-span-12 bg-white overflow-hidden rounded-lg border border-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-xl font-medium text-gray-900">
                  Sobre o aluno
                </h3>
              </div>
              <dl>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-md font-medium text-gray-900">Nome</dt>
                  <dd className="font-regular text-gray-500 text-sm">
                    {purchase?.student.name}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-md font-medium text-gray-900">Email</dt>
                  <dd className="font-regular text-gray-500 text-sm">
                    {purchase?.student.email}
                  </dd>
                </div>
                <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-md font-medium text-gray-900">Indicado em</dt>
                  <dd className="font-regular text-gray-500 text-sm">
                    {calculateDays(purchase?.time.toDate())}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default withRouter(AdminPurchase);
