import db from "src/firebase";
import { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from "@firebase/firestore";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import StudentTable from "src/components/tables/StudentTable";
import CourseEditor from "src/components/CourseEditor";
import CourseSettings from "src/components/CourseSettings";
import AdminLayout from "src/layouts/AdminLayout";

function AdminCourse() {
  let [active, setActive] = useState(0);
  let [purchases, setPurchases] = useState([]);
  const [course, setCourse] = useState({});
  const url = window.location.href;
  const id = url.split("/")[url.split("/").length - 1];

  useEffect(() => {
    getDoc(doc(db, "courses", id))
      .then((doc) => setCourse(doc.data()))
      .then(() => {
        const tmpPurchases = [];
        const q = query(collection(db, "purchases"), where("course", "==", id));
        getDocs(q).then((documents) => {
          documents.forEach((doc) => {
            tmpPurchases.push(doc);
          });
          setPurchases(tmpPurchases);
        });
      });
  }, []);

  if (!course) {
    return null;
  }

  return (
    <AdminLayout>
      <div className="mt-8 md:mt-12 mb-56 w-10/12 p-8 mx-auto min-h-screen bg-white border border-gray-200 rounded-xl">
        <Link to="/admin" className="flex flex-row text-3xl font-medium">
          <ChevronLeft className="mr-3 h-5 w-5 mt-2" />
          {course.title}
        </Link>
        <div className="flex mt-6 gap-4 border-b border-gray-200 ">
          <button
            className={`${
              active === 0 ? "border-b-2 border-gray-900 text-gray-900" : ""
            } font-medium cursor-pointer px-4 py-4 text-sm text-gray-400`}
            onClick={() => setActive(0)}
          >
            Alunos
          </button>

          <button
            className={`${
              active === 1 ? "border-b-2 border-gray-900 text-gray-900" : ""
            } font-medium cursor-pointer px-4 py-4 text-sm text-gray-400`}
            onClick={() => setActive(1)}
          >
            Descrição
          </button>

          <button
            className={`${
              active === 2 ? "border-b-2 border-gray-900 text-gray-900" : ""
            } font-medium cursor-pointer px-4 py-4 text-sm text-gray-400`}
            onClick={() => setActive(2)}
          >
            Configurações
          </button>
        </div>

        {active === 0 ? (
          <StudentTable id={id} purchases={purchases} />
        ) : active === 1 ? (
          <CourseEditor courseToEdit={course} id={id} />
        ) : (
          <CourseSettings course={course} id={id} />
        )}
      </div>
    </AdminLayout>
  );
}

export default withRouter(AdminCourse);
