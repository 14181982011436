import { Fragment, useState, useEffect } from "react";
import db from "src/firebase";
import { doc, updateDoc } from "@firebase/firestore";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDown } from "react-feather";

const statuses = ["Disponível", "Não listado"];

export default function CourseStatus({ course, id }) {
  const [selected, setSelected] = useState(course.status);
  const ref = doc(db, "courses", id);

  useEffect(() => {
    AddToDatabase();
  }, [selected]);

  async function AddToDatabase() {
    await updateDoc(ref, {
      status: selected,
    });
  }

  function generateBtn(status) {
    if (status === "Disponível") {
      return " text-gray-800 border-gray-100";
    } else return " text-gray-800 border-gray-100";
  }

  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="absolute w-32 -mt-4">
        <Listbox.Button
          className={`${generateBtn(selected)}
            relative w-full py-1 px-3 inline-flex text-xs leading-5 font-semibold rounded-lg border-2 z-0`}
        >
          <span className="">{selected}</span>
          <span className="absolute inset-y-0 right-3 flex items-center pr-2 pointer-events-none">
            <ChevronDown className="w-5 h-5"/>
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="z-50 relative w-full py-1 mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-60 ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
            {statuses.map((status) => {
              return (
                <Listbox.Option
                  key={status}
                  value={status}
                  className="cursor-pointer select-none relative py-2 pl-3 pr-4 text-gray-900"
                >
                  <span className="font-normal block truncate">{status}</span>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
