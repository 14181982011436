import { Link } from "react-router-dom";
import Check from "../../images/check.svg"

export default function PurchaseComplete() {

  return (
    <>
    <section className="py-10 h-screen overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="pt-16 pb-16 mx-auto bg-white rounded-3xl md:max-w-2xl border border-gray-200 px-6 md:px-0">
          <div className="md:max-w-md mx-auto">
          <div className="flex mb-4 md:mb-12 items-center justify-center text-center">
              <img className="w-32 h-32" src={Check} alt=""/>
              </div>
              <h2 className="font-heading mb-6 text-4xl text-gray-900 tracking-tight font-semibold text-center">Sucesso!</h2>
              <p className="mb-6 text-gray-500 font-medium">Seu pagamento foi aprovado. Utilize o botão abaixo para entrar na sua conta e acessar o seu curso.</p>
              <div className="flex flex-wrap justify-center -m-2">
                <Link
                  to="/sign-in"
                  className="block px-8 py-3.5 text-md text-center text-white font-medium bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 rounded-lg"
                >
                  Acessar meu curso
                </Link>
              </div>  
          </div>
        </div>
      </div>
    </section>
   
    </>
  );
}
