import "src/editor.css";

export default function PaymentlinkAd({ paymentlink, description, instructor, instructorId }) {
  return (
    <section className="py-4 pb-16 px-4 mx-4 md:px-8 border border-gray-200 rounded-lg bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-md md:max-w-none mx-auto">
          <div className="flex flex-wrap mt-10 -mx-4">
            <div className="w-full md:w-3/3 px-4">
              <div className="p-8 h-full border border-gray-200 rounded-lg">
                <div className="pb-8 border-b border-gray-200">
                  <h1 className="font-heading font-semibold text-2xl text-gray-900 mb-3">{paymentlink?.title || "Título do Link"}</h1>
                  <div className="text-sm  text-gray-950">{instructor} te enviou um link de pagamento</div>
                  <div className="my-8">
                    <div className="text-gray-500" dangerouslySetInnerHTML={{ __html: description }}></div>
                  </div>
                  <div className="inline-flex items-end">
                    <h4 className="text-2xl font-semibold text-gray-900 mr-4">R$ {paymentlink?.price || "0000"}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
