import db from "src/firebase";
import { storage } from "src/firebase";
import { doc, setDoc } from "@firebase/firestore";
import { updateProfile } from "firebase/auth";
import { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import InputField from "../InputField";

export default function InstructorSettings({ user, profile }) {
  const [tmpProfile, setTmpProfile] = useState();
  const [originalProfile] = useState(profile);

  useEffect(() => {
    setTmpProfile(profile);
  }, [profile]);

  const uploadLogo = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    uploadFile(file, "logo");
  };
  
  const uploadBanner = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    uploadFile(file, "banner");
  };  

  const uploadFile = (file, image) => {
    if (!file) {
      return;
    }

    const storageRef = ref(storage, `/files/${user.uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          if (image === "logo") {
            setTmpProfile({ ...tmpProfile, logo: url });
          } else setTmpProfile({ ...tmpProfile, banner: url });
        });
      }
    );
  };

  async function handleSave() {
    const instructorsRef = doc(db, "instructors", user?.uid);
    await setDoc(instructorsRef, {
      instructor: tmpProfile.instructor,
      website: tmpProfile.website,
      crm: tmpProfile.crm,
      logo: tmpProfile.logo,
      banner: tmpProfile.banner,
      bio: tmpProfile.bio,
      about: tmpProfile.about,
      background: tmpProfile.background,
      specialty: tmpProfile.specialty,
    });

    updateProfile(user, {
      displayName: tmpProfile.instructor,
      photoURL: tmpProfile.logo,
    });
  }

  return (
    <div className="md:mt-0 mt-16 bg-white p-8 rounded-lg border border-gray-200">
      <h3 className="text-2xl font-medium leading-6 text-gray-900">
        Editar seu perfil
      </h3>
      <p className="mt-4 text-sm w-2/3 text-gray-500">
        Todas essas informações serão públicas, então cuidado com as alterações. Não se esqueça de salvar suas alterações no final da página.
      </p>
      <div className="flex mb-12 gap-3 relative mt-6">
        <img
          alt="logo"
          className="md:h-24 md:w-24 w-16 h-16 md:mr-6 mr-4 rounded-lg"
          src={tmpProfile?.logo}
        />

        <div>
          <label className="uppercase tracking-wide text-gray-900 text-xs font-semibold mb-2 mt-8">
            Foto de perfil
          </label>

          <div>
            <input type="file" name="logo" onChange={uploadLogo} />
            <button
              type="button"
              className="hover:opacity-80  cursor-pointer items-center font-semibold text-md justify-center px-8 py-3 bg-gray-900 text-white rounded-lg"
              onSubmit={uploadLogo}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mt-6">
        <InputField
          className="col-span-2"
          label="Seu nome"
          type="text"
          value={tmpProfile?.instructor}
          onChange={(e) => {
            setTmpProfile({
              ...tmpProfile,
              instructor: e.target.value,
            });
          }}
          placeholder="Nome"
        />

        <InputField
          className="col-span-2"
          label="Website"
          type="text"
          value={tmpProfile?.website}
          onChange={(e) => {
            setTmpProfile({
              ...tmpProfile,
              website: e.target.value,
            });
          }}
          placeholder="www.empresa.com"
        />
      </div>
      <div className="grid grid-cols-4 gap-4 mt-6">
        <InputField
          className="col-span-2"
          label="Número de CRM"
          type="text"
          value={tmpProfile?.crm}
          onChange={(e) => {
            setTmpProfile({
              ...tmpProfile,
              crm: e.target.value,
            });
          }}
          placeholder="00000/00"
        />

        <InputField
          className="col-span-2"
          label="Formação"
          type="text"
          value={tmpProfile?.background}
          onChange={(e) => {
            setTmpProfile({
              ...tmpProfile,
              background: e.target.value,
            });
          }}
          placeholder="Universidade X"
        />
      </div>

      <InputField
        label="Bio"
        type="text"
        className="mt-4"
        value={tmpProfile?.bio}
        onChange={(e) => {
          setTmpProfile({
            ...tmpProfile,
            bio: e.target.value,
          });
        }}
        placeholder="Adicione uma frase curta representando sua empresa."
      />

      <label className="block text-gray-900 text-sm font-semibold mb-2">
        Sobre
      </label>
      <textarea
        className="block border border-grey-light w-full p-3 rounded-lg mb-4 focus:ring-gray-900 focus:border-gray-900"
        rows="8"
        placeholder="Adicione um resumo sobre seus produtos ou serviços."
        value={tmpProfile?.about}
        onChange={(e) =>
          setTmpProfile({
            ...tmpProfile,
            about: e.target.value,
          })
        }
      />

      <div className="flex mb-12 gap-3 relative mt-12">
        <img
          alt="banner"
          className="w-1/3 h-auto md:mr-6 mr-4 rounded-lg"
          src={tmpProfile?.banner}
        />

        <div>
          <label className="uppercase tracking-wide text-gray-900 text-xs font-semibold mb-2 mt-8">
            Banner
          </label>

          <div>
            <input type="file" name="banner" onChange={uploadBanner} />
            <button
              type="button"
              className="hover:opacity-80  cursor-pointer items-center font-semibold text-md justify-center px-8 py-3 bg-gray-900 text-white rounded-lg"
              onSubmit={uploadBanner}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>

      <InputField
        className="col-span-2"
        label="Sua especialidade"
        type="text"
        value={tmpProfile?.specialty}
        onChange={(e) => {
          setTmpProfile({
            ...tmpProfile,
            specialty: e.target.value,
          });
        }}
        placeholder="Digite uma legenda para seu banner. Nossa recomendação é (Nome da empresa) + Todos os direitos reservados."
      />
      <div className="flex items-center pt-6">
        <div
          className="hover:opacity-80 flex cursor-pointer items-center font-semibold text-md justify-center px-8 py-3 bg-gray-900 text-white rounded-lg"
          onClick={() => handleSave()}
        >
          Salvar alterações
        </div>

        <div
          className="ml-2 font-semibold mr-2 cursor-pointer border-b-2 border-gray-900  px-8 py-3 rounded-lg border-none"
          onClick={() => setTmpProfile(originalProfile)}
        >
          Cancelar
        </div>
      </div>
    </div>
  );
}
