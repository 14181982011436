import React from "react";
import CourseTable from "src/components/tables/CourseTable";
import NoCourses from "src/components/emptyStates/NoCourses";
import { useCourses } from "src/hooks/useCourses";
import AdminLayout from '../../layouts/AdminLayout';
import Banner from '../../images/banner.png'

export default function AdminSales() {
  const courses = useCourses();

  if (!courses) {
    return <NoCourses />;
  }

  return (
    <AdminLayout>
      <section class="pt-16 h-screen md:mx-8 mx-4">
        <div class="container px-4 mx-auto">
          <h2 className="text-2xl tracking-tight font-semibold text-gray-900 leading-none line-clamp-1 overflow-hidden mb-8">Selecione um produto para ver suas vendas:</h2>
          <div className="container overflow-hidden mx-auto mt-8 p-8 bg-white border border-gray-200 rounded-lg">

            <CourseTable courses={courses} />
          </div>
        </div>
      </section>
    </AdminLayout>
  );
}
