import { useEffect } from "react";

function PrivacyPolicy() {
  return (
    <div className=" text-xl">
      <div className="md:py-16 py-16 max-w-4xl mx-auto px-3 text-left">
        <h2 className="mb-4 font-sans text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl mt-8 text-center sm:leading-none">
          Política de privacidade
        </h2>
        <p className="text-sm mt-8 text-left text-gray-900 md:text-md">
          Nós da Medfy nos preocupamos com sua privacidade e reconhecemos a necessidade de sermos abertos sobre como coletamos e gerenciamos seus dados pessoais ("informações sobre uma pessoa viva").
          Como resultado, de acordo com a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados ou “LGPD”), Lei nº 12.965/2014 (Marco Civil da Internet), e demais leis aplicáveis, elaboramos esta Política de Privacidade e Proteção de Dados com o objetivo principal de fornecer informações sobre o processamento de dados pessoais que são coletados, armazenados e usados ​​de alguma forma. 
          <br/>
          <br/>
          Visitantes da nossa plataforma online (https://medfy.net/) que tenham acesso às vagas disponíveis ("Visitantes"); usuários individuais que se cadastram em nossa plataforma e se candidatam ou indicam candidatos a oportunidades em aberto ("Usuário"); dados recolhidos por nós para o desenvolvimento das nossas atividades.
          Usuários representantes do Cliente, ou seja, representantes de pessoas jurídicas que se cadastram em nossa plataforma e contratam nossos serviços de recrutamento e seleção, serviços de indicação de candidatos para vagas (“Representante do Cliente”); todos aqueles que nos contactam através dos canais de comunicação disponíveis na nossa plataforma online; e aqueles que se registram em nosso serviço de correspondência.
          Como resultado, se você se enquadrar em uma das categorias listadas acima, esta Política de Privacidade se aplicará aos dados pessoais que tratamos. A aceitação de nossa Política de Privacidade está condicionada ao seu fornecimento voluntário de dados em nosso site ou através de outros meios de contato.
          Se você não concordar, por favor, não use nosso site e/ou não nos forneça informações pessoais por meio de nossos canais.
          <br/>
          <br/>
          Se você nos fornecer dados pessoais de outras partes (por exemplo, em formulários de indicação, currículos, portfólios ou comunicações), você declara que tem autoridade legal para fazê-lo e que é responsável perante o titular dos dados por isso.
          <br/>
          <br/>
          NOSSOS RESULTADOS NO PROCESSAMENTO DE INFORMAÇÕES PESSOAIS
          <br/>
          <br/>
          Na maioria das circunstâncias, operamos como operador no tratamento de dados pessoais ao mesmo tempo que prestamos os nossos serviços de recrutamento e seleção; no entanto, há momentos em que funcionamos como um controlador. Vamos dar uma olhada mais de perto no histórico de cada performance.
          <br/>
          <br/>
          1.2. Como supervisor
          <br/>
          <br/>
          A organização individual ou jurídica responsável por tomar decisões sobre o processamento de dados pessoais é conhecida como controlador de dados pessoais. Funcionamos como um controlador neste sentido:
          <br/>
          <br/>
          Usamos os dados que você fornece para encaminhar campanhas de marketing de emprego, bem como para personalizar e melhorar sua experiência, fornecendo conteúdo personalizado; usamos os dados que você fornece para entrar em contato com você e responder à sua demanda na interação conosco; usamos os dados que você fornece para entrar em contato com você e responder à sua demanda na interação conosco; usamos os dados que você fornece para encaminhar campanhas de marketing de emprego, bem como para personalizar e melhorar sua experiência, fornecendo conteúdo personalizado; usamos os dados que você fornece para encaminhar campanhas de marketing de emprego
          <br/>
          <br/>
          Também é concebível que suas informações sejam processadas por muitos controladores. Desta forma, as informações que você fornece podem ser compartilhadas com outros controladores, como nossos clientes, que
          utilizam nossos serviços e tem acesso aos nossos bancos de talentos, o que é feito especialmente no contexto de procedimentos de recrutamento e seleção de candidatos, ou para ofertas de comunicação, produtos e serviços que possam ser de interesse dos candidatos
          Nossos clientes não poderão importar ou baixar totalmente seus dados, mas poderão se conectar diretamente com você para realizar um processo de seleção. Os Clientes são responsáveis ​​pelo resultado final da contratação, e nós da Medfy não temos controle sobre como nossos Clientes conduzem e decidem sobre seus procedimentos de seleção, ou como nossos Clientes, como controladores de dados, utilizarão as informações do Usuário fora de sua plataforma.
          <br/>
          <br/>
          1.2. Como Operador
          <br/>
          <br/>
          O Operador de dados pessoais é qualquer pessoa física ou jurídica que processe dados pessoais em nome do controlador.
          <br/>
          <br/>
          A este respeito, quando tratamos dados pessoais a pedido do Cliente, apenas funcionamos como operador em parte do tratamento dos dados pessoais adquiridos, efetuando o tratamento por conta do responsável pelo tratamento e de acordo com os seus requisitos.
          É importante notar que, nestes casos, nós, enquanto operadores, não somos responsáveis ​​pelas decisões de tratamento do responsável pelo tratamento, que podem estar sujeitas a uma política de privacidade e procedimentos diferentes dos nossos, sendo mesmo da sua exclusiva responsabilidade indicar a base legal. no processamento de dados de candidatos a emprego, obtenção de consentimento e/ou compartilhamento de dados. Como resultado, qualquer exercício de seus direitos deve ser feito na direção do Cliente que atua como controlador.
          <br/>
          <br/>
          Por fim, podemos estabelecer um relacionamento com o Cliente e auxiliá-lo no cumprimento dos deveres decorrentes de suas atividades de tratamento de dados pessoais, bem como fornecer sugestões para práticas adequadas de tratamento de dados. Se o Cliente tiver suas próprias políticas e procedimentos de privacidade para o processamento de dados pessoais sob seu controle, ele deve compartilhá-los conosco.
          Por fim, mesmo atuando como operador, garantimos o mesmo grau de cuidado, cuidado e segurança nos dados que processamos como quando atuamos como controlador.
          <br/>
          <br/>
          NOSSO TRATAMENTO DE DADOS PESSOAIS – FINALIDADE E BASES LEGAIS
          <br/>
          <br/>
          Usuário (2.1)
          <br/>
          <br/>
          Quando você, Usuário, preenche o formulário de cadastro da nossa plataforma, coletamos seu nome e sobrenome, endereço de e-mail, telefone, estado e cidade de residência, foto pessoal (opcional), link do perfil do LinkedIn, recursos técnicos, preferências de contratação , expectativas salariais e qualquer outra informação.
          Quando você, Usuário, preenche o formulário de indicação de candidatos da nossa plataforma, coletamos o nome e sobrenome, endereço de e-mail, telefone, estado e cidade de residência, foto pessoal (opcional), link do perfil do LinkedIn, recursos técnicos, preferências de contratação , expectativas salariais e qualquer outra informação. 
          Nós da Medfy coletamos essas informações, armazenamos e usamos para registrá-lo em vagas de emprego com nossos clientes. Seus dados também podem ser publicados em nossa plataforma a partir daí.
          Também podemos exigir documentos e/ou informações de você, o Usuário, que julgarmos necessários para a prestação de serviços, a fim de verificar a veracidade das informações enviadas.
          Quando você entrar em contato conosco através dos endereços de e-mail da plataforma ("Contato"), bem como de nossas contas de mídia social ("Mídia Social"),
          <br/>
          <br/>
          Coletamos seu endereço de e-mail, assunto de contato, conteúdo da mensagem e qualquer outra informação pessoal que possa estar incluída no conteúdo da comunicação que você nos fornece por meio de mídia. Nós da Medfy coletamos, armazenamos e utilizamos suas informações para nos comunicarmos com você e responder às suas solicitações durante todo o seu relacionamento conosco.
          Também utilizamos seus dados e informações sobre sua experiência com Clientes e as vagas que eles fornecem, como por meio de pesquisas de satisfação, para avaliar empresas que oferecem vagas, estabelecer classificações e, assim, aprimorar a experiência do usuário. O Cliente apenas terá acesso a informação genérica e não individualizada sobre os Usuários nestas situações.
          Se você, como Usuário, decidir seguir uma empresa de Cliente, nosso sistema compartilhará todas as suas informações de contato com essa empresa e o Cliente poderá enviar e-mails e materiais.
          <br/>
          <br/>
          Também usamos os dados pessoais que coletamos para treinar algoritmos de inteligência artificial e aprendizado de máquina que serão usados ​​na recomendação, classificação e outras funcionalidades da plataforma, principalmente para melhorar a seleção de candidatos para vagas específicas e tornar o processo de recrutamento mais eficiente. Gostaríamos de salientar que o uso de seus dados pessoais não resultará em nenhum tratamento discriminatório.
          Quando você se inscreve em nossa lista de e-mail e nos fornece seu nome e endereço de e-mail, usamos suas informações para enviar materiais como nosso boletim informativo.
          Também podemos ter acesso a informações sobre você que foram tornadas públicas na rede LinkedIn, bem como outras plataformas de armazenamento de currículos e/ou portfólios, como o GitHub.
          Além disso, podemos realizar campanhas de marketing para vagas de emprego específicas para que você se registre livremente em nossa plataforma, respeitando sempre as expectativas razoáveis ​​do titular dos dados.
          <br/>
          <br/>
          2.2. Representante do cliente
          <br/>
          <br/>
          Para fins de registro do Cliente em nossos serviços, coletaremos, manteremos e utilizaremos seu nome, endereço de e-mail e número de telefone de contato.
          Também podemos solicitar documentos e/ou informações de você, Representante do Cliente, que julgarmos necessários para a prestação de serviços, a fim de verificar a veracidade das informações fornecidas.
          Os dados pessoais assim obtidos são utilizados para processos preparatórios, cumprimento de contrato em que o titular seja parte, ou com base em interesse justificável. tudo de acordo com o art. 7º, incisos V e IX, e art. 10 da LGPD.
          <br/>
          <br/>
          Quando usamos seus dados pessoais para comunicações de campanha, por exemplo, usaremos o teste de interesse legítimo para garantir que esse tratamento nos ajude a fornecer um serviço mais personalizado e eficiente, eventualmente fornecerá ao Usuário uma oportunidade de emprego ou fornecerá o Usuário com algum outro benefício.
          Por fim, reservamo-nos o direito de recorrer a outras bases legais previstas na LGPD, tais como:
          <br/>
          <br/>
          i) O exercício regular dos direitos, como quando for necessário tratamento para aconselhamento jurídico ou quando for legalmente recomendada a retenção de determinados dados ou sua utilização em qualquer processo judicial (art. 7º, inciso VI da LGPD);
          <br/>
          <br/>
          ii) o cumprimento de obrigação legal ou regulatória, como quando somos obrigados a cooperar com o fisco informando dados que processamos (art. 7º, inciso II da LGPD);
          <br/>
          <br/>
          iii) tratamento para proteção ao crédito (art. 7º, inciso X da LGPD); ou
          <br/>
          <br/>
          iv) o consentimento do titular dos dados (art. 7. I da LGPD).
          <br/>
          <br/>
          Não coletamos dados pessoais sensíveis ("dados sobre origem racial ou étnica, convicções religiosas, opinião política, filiação a sindicato ou organização de natureza religiosa, filosófica ou política, dados sobre saúde ou vida sexual, dados genéticos ou biométrico") por meio de nossos formulários ou canais de comunicação na Medfy.
          No entanto, se você optar por fornecer voluntariamente quaisquer dados pessoais sensíveis em seu currículo, portfólio anexado, ou assunto e/ou mensagem em um e-mail ou outra forma de contato com a empresa, o fornecimento desses dados implica que você está ciente os termos e condições estabelecidos nesta Política de Privacidade e Proteção de Dados Pessoais e que dá o seu consentimento livre e inequívoco para o tratamento dos seus dados pessoais para os fins aqui determinados.
          <br/>
          <br/>
          Além disso, alertamos que podemos obter dados pessoais confidenciais disponibilizados publicamente pelo Usuário por meio de plataformas profissionais da web, como o LinkedIn, de tempos em tempos.
          Se você não deseja que seus dados confidenciais sejam usados ​​dessa maneira, não os compartilhe conosco. O titular não sofrerá qualquer penalidade se não fornecer dados sensíveis e não consentir o seu tratamento.
          <br/>
          <br/>
          COLETA AUTOMÁTICA DE DADOS
          <br/>
          <br/>
          Usamos cookies e outras tecnologias de coleta automática de informações na Medfy para coletar dados agregados e anônimos sobre suas ações em nosso site por meio de provedores de serviços de análise de terceiros, como o Google Analytics, coletando histórico de navegação na plataforma, mapa de calor por onde os visitantes viajam e assim por diante , tudo com o objetivo de ajustar e melhorar a experiência do nosso site para os visitantes e usuários do nosso site.
          <br/>
          <br/>
          Não associamos as informações registradas por nosso programa com nenhuma informação de identificação pessoal que você nos forneceu.
          Lembre-se de que você pode interromper o Google Analytics usando um complemento do navegador. Visite http://www.google.com/analytics/learn/privacy.html para obter informações adicionais sobre o Google Analytics.
          <br/>
          <br/>
          PROCESSAMENTO E ARMAZENAMENTO DE INFORMAÇÕES PESSOAIS
          <br/>
          <br/>
          Os dados pessoais sob nosso controle serão mantidos por um período de 10 anos (considerando o prazo de prescrição) pelo exercício regular do direito, de acordo com as regras vigentes e a finalidade de cada tratamento. Esses dados serão mantidos por um período de três anos após o usuário deixar de usar ou interagir com nossa plataforma, para uso e visualização por nossos clientes de que esses dados serão anonimizados com segurança.
          <br/>
          <br/>
          Entre em contato conosco pelo e-mail contato@medfy.net se desejar informações adicionais sobre os períodos de armazenamento precisos para cada coleta de dados. Os dados pessoais serão removidos por meio de procedimentos de descarte seguro ou anonimizados ao final do prazo especificado e da exigência legal.
          Os dados podem ser destruídos antes do tempo interno de exclusão definido por nós, se o titular dos dados o solicitar. No entanto, é possível que os dados precisem ser mantidos em nosso banco ou processados ​​por motivos de exercício de direitos, lei, ordem judicial, prevenção de fraudes, proteção de crédito ou outros interesses legítimos - nesse caso, notificaremos você sobre a não -exclusão dos dados que você solicitou a exclusão.
          <br/>
          <br/>
          Por fim, observamos que, na medida permitida ou exigida por lei, podemos excluir suas informações pessoais a qualquer momento e não temos o dever de guardá-las.
          <br/>
          <br/>
          Por fim, gostaríamos de informar que, na medida autorizada ou exigida por lei, podemos apagar suas informações pessoais a qualquer momento e não temos obrigação de mantê-las seguras.
          <br/>
          <br/>
          PRÁTICAS DE SEGURANÇA QUE FORAM IMPLEMENTADAS
          <br/>
          <br/>
          Empregamos métodos e processos que atendem a critérios razoáveis ​​de precaução e cuidado, levando em consideração as capacidades técnica e economicamente viáveis ​​da tecnologia relevante para a Internet, como a implantação de um firewall para impedir o acesso não autorizado, entre outras etapas.
          Não compartilhamos ou vendemos suas informações pessoais com mais ninguém. Qualquer membro da Medfy que tenha acesso às suas informações e dados pessoais fica ciente de sua natureza confidencial, bem como da necessidade de aderir a esta Política de Privacidade.
          <br/>
          <br/>
          Não nos responsabilizamos pelas atividades dos titulares, incluindo situações de negligência que possam expor seus dados, razão pela qual recomendamos o uso de software antivírus, processos de segurança da informação e outras medidas de segurança para manter os dados pessoais seguros.
          Além disso, você aceita que não existe segurança completa na Internet, principalmente no caso de intrusões ilegais em redes de dados seguras por hackers e outras situações de eventos de segurança.
          Apesar disso, nos dedicamos a proteger e prevenir o acesso indesejado aos seus dados. No entanto, não podemos ser responsabilizados pelas ações de outras pessoas que possam obter acesso não autorizado às suas informações. Não oferecemos garantia, expressa ou implícita, de que o acesso não autorizado aos seus dados e comunicações nesses casos será impedido.
          <br/>
          <br/>
          Nós da Medfy cumpriremos todas as obrigações de informação e transparência exigidas por lei e órgãos reguladores, como a publicação de um aviso informativo em nosso site ou o envio de e-mail aos titulares, no caso de quaisquer incidentes de segurança que resultem na exposição ou acesso não autorizado de seus dados pessoais.
          <br/>
          <br/>
          AUTORIDADES DE COMPARTILHAMENTO DE DADOS PESSOAIS 
          <br/>
          <br/>
          Quando houver determinação legal, solicitação de autoridade administrativa ou ordem judicial, os dados pessoais adquiridos por nós e as ações registradas poderão ser compartilhados com as autoridades judiciais ou administrativas competentes.
          Clientes. As informações pessoais dos candidatos serão compartilhadas com nossos Clientes para fins de condução do processo de recrutamento, seleção e contratação de acordo com os parâmetros estabelecidos nas seções anteriores.
          <br/>
          <br/>
          3. O uso de subprocessadores de dados, parceiros de negócios, ferramentas de gerenciamento de banco de dados, e-mails, fluxos internos e atendimento ao usuário, entre outras coisas, podem resultar no compartilhamento de dados para a prestação de nossos serviços. Cuidamos de pessoa física. Como resultado, podemos contar com a ajuda de provedores de serviços terceirizados para operar nossos sites e infraestruturas relacionadas, bem como realizar auditorias e oferecer serviços jurídicos. Google Cloud Platform e AWS (pacote de computação em nuvem); Google Drive (serviço de armazenamento e sincronização de arquivos); Sendgrid (plataforma de comunicação com o cliente para e-mail transacional e de marketing); Esses são apenas alguns dos processadores e serviços terceirizados que utilizamos.
          <br/>
          <br/>
          TRANSFERÊNCIA PARA OUTRO PAÍS
          Qualquer troca de dados sempre será feita dentro dos parâmetros e objetivos de nossa empresa. Quando utilizamos software de terceiros que armazena dados fora do país, como o Google Drive, podemos possibilitar transferências internacionais de dados (EUA). Quando enviamos dados pessoais para fora do Brasil em determinadas circunstâncias, tomamos todas as precauções e salvaguardas necessárias para garantir que os dados pessoais sejam protegidos.
          Se você concordar, poderemos divulgar seus dados pessoais de maneiras diferentes das indicadas neste capítulo ocasionalmente.
          <br/>
          <br/>
          OS DIREITOS DO TITULAR DE DADOS PESSOAIS
          Na Medfy, respeitamos seus direitos como controlador de dados conforme o artigo 18 da LGPD, e prometemos que você pode nos solicitar a qualquer momento para:
          Confirmação da presença do seu processamento de dados pessoais; Acesso aos dados que processamos; Correção de dados pessoais de informações ausentes, incorretas ou desatualizadas;
          <br/>
          <br/>
          Anonimização, bloqueio e exclusão de dados considerados desnecessários, excessivos ou processados ​​em desacordo com as regras da LGPD;
          Portabilidade de dados para outro provedor de serviço ou produto mediante solicitação, em conformidade com os regulamentos das autoridades nacionais, mantendo segredos comerciais e industriais;
          Se você se opuser ao tratamento, seu objetivo ou a maneira como ele é realizado, você tem o direito de se opor ao processamento de dados e excluir seus dados pessoais.
          Informações sobre compartilhamento de dados, incluindo instituições governamentais e comerciais com as quais trocamos dados;
          Informações de consentimento, incluindo a possibilidade de não fornecer consentimentos específicos e as repercussões da recusa; e Revogação de Consentimento.
          Além disso, suas informações pessoais devem ser mantidas atualizadas e precisas.
          <br/>
          <br/>
          Você pode alterá-los a qualquer momento para realizar imediatamente quaisquer atualizações e modificações por esse motivo.
          Caso não seja possível a adoção imediata das ações acima mencionadas, emitiremos uma resposta informando os fundamentos de fato ou de direito para isso, conforme disposto no art. 18, 4 da LGPD.
          <br/>
          <br/>
          REQUERIMENTOS GERAIS
          <br/>
          <br/>
          Se você tiver alguma dúvida sobre esta Política de Privacidade, desejar mais informações sobre como gerenciamos seus dados pessoais ou desejar exercer algum de seus direitos, entre em contato com nosso suporte em contato@medfy.net
          Reservamo-nos o direito de alterar esta Declaração de Privacidade a qualquer momento, com a nova versão entrando em vigor imediatamente após sua publicação em nosso site.
          <br/>
          <br/>
          Se você não concordar com qualquer uma das modificações da Política de Privacidade, você tem o direito de ter seus dados pessoais excluídos dentro das limitações contratuais e legalmente permitidas.
          Esta Política de Privacidade será controlada e interpretada apenas pela legislação brasileira, particularmente pela Lei Geral de Proteção de Dados. Qualquer reclamação, desacordo ou controvérsia decorrente ou a ela vinculada deverá ser tratada na Comarca de Minas Gerais/MG.
        </p>
        <hr className="w-full my-8 border-gray-200" />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
