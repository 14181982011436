import InputField from "src/components/InputField";
import AdminLayout from "src/layouts/AdminLayout";
import CourseAd from "src/components/CourseAd";
import db, { useAuthState } from "src/firebase";
import ReactQuill from "react-quill";
import { useCallback, useEffect, useState } from "react";
import {
  addDoc,
  doc,
  setDoc,
  collection,
  Timestamp,
} from "@firebase/firestore";
import { storage } from "src/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { useHistory, Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";

export default function CourseCreator({ courseToEdit, id }) {
  let history = useHistory();
  const { user } = useAuthState();

  const [course, setCourse] = useState(
    courseToEdit || {
      instructor: user.displayName,
      instructorId: user.uid,
      logo: user.photoURL,
      title: "",
      category: "",
      price: 0,
      paymentUrl: "",
      level: "",
      modules: 0,
      description: "",
      status: "Indisponível",
      time: Timestamp.now(),
      videos: [],
      materials: [],
    }
  );

  const [description, setDescription] = useState(course?.description);

  useEffect(() => {
    setCourse({ ...course, description: description });
  }, [description]);

  let isComplete =
    course.title.length > 0 &&
    course.category.length > 0 &&
    course.price.length > 0 &&
    course.level.length > 0 &&
    course.modules.length > 0 &&
    course.description.length > 0;

  const [logoUrl, setLogoUrl] = useState(course?.logo || "");
  const [logoUploadProgress, setLogoUploadProgress] = useState(null);
  
  const uploadLogo = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const storageRef = ref(storage, `logos/${user.uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLogoUploadProgress(progress);
      },
      (error) => {
        console.error(error);
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        setLogoUrl(url);
        setCourse({ ...course, logo: url });
        setLogoUploadProgress(null);
      }
    );
  };  

  const [videoInputs, setVideoInputs] = useState([0]);
  const [videoUploadProgress, setVideoUploadProgress] = useState([-1]);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const addVideoInput = () => {
    setVideoInputs((prevState) => [...prevState, ""]);
  };  

  const handleVideoUpload = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `courses/${course.title}/videos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setVideoUploadProgress((prevState) => {
            const newState = [...prevState];
            newState[index] = progress;
            return newState;
          });
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const videoURL = await getDownloadURL(uploadTask.snapshot.ref);
          setCourse((prevState) => {
            return { ...prevState, videos: [...prevState.videos, { name: file.name, url: videoURL, title: prevState.videos[index]?.title || "" }] };
          });
          setUploadedVideos((prevState) => {
            return [...prevState, { name: file.name, url: videoURL }];
          });
        }
      );
    }
  };  

  const [materialUploadProgress, setMaterialUploadProgress] = useState([-1]);
  const [uploadedMaterials, setUploadedMaterials] = useState([]);
  const [materialInputs, setMaterialInputs] = useState([0]);

  const addMaterialInput = () => {
    setMaterialInputs((prevState) => [...prevState, ""]);
  };  

  const handleMaterialUpload = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `courses/${course.title}/materials/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setMaterialUploadProgress((prevState) => {
            const newState = [...prevState];
            newState[index] = progress;
            return newState;
          });
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const materialURL = await getDownloadURL(uploadTask.snapshot.ref);
          setCourse((prevState) => {
            return { ...prevState, materials: [...prevState.materials || [], { name: file.name, url: materialURL }] };
          });
          setUploadedMaterials((prevState) => {
            return [...prevState, { name: file.name, url: materialURL }];
          });
          setMaterialUploadProgress((prevState) => {
            const newState = [...prevState];
            newState[index] = -1;
            return newState;
          });
        }
      );
    }
  };
  
  const addToDatabase = useCallback(async () => {
    if (courseToEdit && id) {
      await setDoc(doc(db, "courses", id), course);
    } else {
      await addDoc(collection(db, "courses"), course);
    }
    history.push("/admin");
  }, [course, user, db, id]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };
  
  function WaitingBtn() {
    return (
      <div className="cursor-not-allowed transform ease-in w-72 duration-100 flex mr-2 items-center font-semibold text-md justify-center px-8 py-3 bg-transparent border border-gray-200 rounded-lg text-gray-900">
        Aguardando respostas...
      </div>
    );
  }

  return (
    <AdminLayout>
      <div className="grid grid-cols-12 overflow-y-hidden h-screen">
        <div className="col-span-4  px-12 py-4 overflow-y-scroll">

        <label className="block text-gray-900 text-sm font-semibold mb-2 mt-6">
          Logo do curso
        </label>

        <input
          type="file"
          id="logo-input"
          className="hidden"
          accept="image/*"
          onChange={uploadLogo}
        />

        <label
          htmlFor="logo-input"
          className={`${
            logoUploadProgress < 100 ? "cursor-pointer" : ""
          } flex justify-center items-center w-full py-3 bg-gray-200 border-2 border-dashed border-gray-400 cursor-pointer rounded-lg text-gray-600`}
        >
          {logoUploadProgress < 100 ? "Arraste a logo do curso para cá ou clique aqui" : logoUrl.split("/").pop()}
        </label>

        {logoUploadProgress !== null && (
          <div className="mb-6">
            <div className="overflow-hidden h-1.5 text-xs flex rounded bg-gray-200">
              <div
                style={{ width: `${logoUploadProgress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
              ></div>
            </div>
            <p className="text-center mt-1">{Math.round(logoUploadProgress)}%</p>
          </div>
        )}


          {videoInputs.map((_, index) => (
            <div key={index} className="relative mt-4">

              <div className="flex flex-row items-center justify-start">
                <div>
                  <p className="block text-gray-900 text-sm font-semibold mb-2">Aula {index + 1}</p>
                  <input
                    type="file"
                    accept="video/*"
                    id={`video-input-${index}`}
                    onChange={(e) => handleVideoUpload(e, index)}
                    className="hidden"
                  />
                  <label
                    htmlFor={`video-input-${index}`}
                    className={`${
                      videoUploadProgress[index] >= 0 && videoUploadProgress[index] < 100
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    } flex justify-center items-center w-full py-3 bg-gray-200 border-2 border-dashed border-gray-400 cursor-pointer rounded-lg px-4 text-gray-600 line-clamp-1 overflow-hidden`}
                  >
                    {uploadedVideos[index] ? uploadedVideos[index].name : "Arraste um vídeo para cá ou clique"}
                  </label>
                </div>
                <InputField
                  type="text"
                  label="Título da aula"
                  className="mt-2 ml-4 -mb-3"
                  value={course.videos[index]?.title || ""}
                  onChange={(e) => {
                    const newTitle = e.target.value;
                    setCourse((prevState) => {
                      const newVideos = [...prevState.videos];
                      newVideos[index] = { ...newVideos[index], title: newTitle };
                      return { ...prevState, videos: newVideos };
                    });
                  }}
                  placeholder="Título da aula"
                />
              </div>
              {videoUploadProgress[index] >= 0 && videoUploadProgress[index] < 100 && (
                <div className="absolute top-0 left-0 w-full h-full bg-transparent bg-opacity-50 flex items-center justify-center">
                  <div className="relative w-3/4">
                    <div className="overflow-hidden h-1.5 text-xs flex rounded bg-gray-200">
                      <div
                        style={{ width: `${videoUploadProgress[index]}%` }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
                      ></div>
                    </div>
                    <p className="text-center mt-1">{Math.round(videoUploadProgress[index])}%</p>
                  </div>
                </div>
              )}
            </div>
          ))}

          <button
            onClick={addVideoInput}
            className="text-center font-medium transform hover:bg-gray-900 cursor-pointer text-md px-8 py-3 bg-gray-800 rounded-lg text-white mt-4"
          >
            Adicionar aula
          </button>

          <InputField
            className="mt-8"
            type="text"
            label="Título do curso"
            value={course.title}
            onChange={(e) => {
              setCourse({
                ...course,
                title: e.target.value,
              });
            }}
            placeholder=""
          />
          <div className="grid grid-cols-4 gap-4 mt-6">
            <InputField
              className="col-span-2"
              type="number"
              label="Preço em R$"
              placeholder="2500"
              value={course.price}
              onChange={(e) => {
                setCourse({
                  ...course,
                  price: e.target.value,
                });
              }}
            />
            <InputField
              className="col-span-2"
              label="Nível de Ensino"
              type="text"
              placeholder="Residência"
              value={course.level}
              onChange={(e) => {
                setCourse({
                  ...course,
                  level: e.target.value,
                });
              }}
            />
          </div>
          <div className="grid grid-cols-4 gap-4 mt-1">
            <InputField
              className="col-span-2"
              type="number"
              label="Módulos"
              placeholder="10"
              value={course.modules}
              onChange={(e) => {
                setCourse({
                  ...course,
                  modules: e.target.value,
                });
              }}
            />

            <InputField
              className="col-span-2"
              type="text"
              label="Categoria"
              placeholder="Neurologia"
              value={course.category}
              onChange={(e) => {
                setCourse({
                  ...course,
                  category: e.target.value,
                });
              }}
            />
          </div>

          <label className="block text-sm font-semibold text-gray-950 mt-6 mb-2">
            Descrição do curso
          </label>

          <ReactQuill
            modules={modules}
            theme="snow"
            value={description}
            onChange={setDescription}
            placeholder="Descreva aqui seu curso..."
          />

            {materialInputs.map((_, index) => (
              <div key={index} className="relative mt-4">
                <div className="flex flex-row items-center justify-start">
                  <div>
                    <p className="block text-gray-900 text-sm font-semibold mb-2">Material {index + 1}</p>
                    <input
                      type="file"
                      accept="application/pdf"
                      id={`material-input-${index}`}
                      onChange={(e) => handleMaterialUpload(e, index)}
                      className="hidden"
                    />
                    <label
                      htmlFor={`material-input-${index}`}
                      className={`${
                        materialUploadProgress[index] >= 0 && materialUploadProgress[index] < 100
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      } flex justify-center items-center w-full py-3 bg-gray-200 border-2 border-dashed border-gray-400 cursor-pointer rounded-lg px-4 text-gray-600 line-clamp-1 overflow-hidden`}
                    >
                      {uploadedMaterials[index] ? uploadedMaterials[index].name : "Arraste um arquivo PDF para cá"}
                    </label>
                  </div>
                  {materialUploadProgress[index] >= 0 && materialUploadProgress[index] < 100 && (
                    <div className="absolute top-0 left-0 w-full h-full bg-transparent bg-opacity-50 flex items-center justify-center">
                      <div className="relative w-3/4">
                        <div className="overflow-hidden h-1.5 text-xs flex rounded bg-gray-200">
                          <div
                            style={{ width: `${materialUploadProgress[index]}%` }}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
                          ></div>
                        </div>
                        <p className="text-center mt-1">{Math.round(materialUploadProgress[index])}%</p>
                      </div>
                    </div>
                  )}
                  <button
                      onClick={addMaterialInput}
                      className="text-center font-medium transform hover:bg-gray-900 cursor-pointer text-md px-8 py-3 bg-gray-800 rounded-lg text-white mt-7 ml-6"
                    >
                      Adicionar
                    </button>
                </div>
              </div>
            ))}

          <div className="flex items-center pt-6">
            {isComplete ? (
              <button
                className="text-center font-medium transform hover:bg-gray-900 cursor-pointer text-md px-8 py-3 bg-gray-800 rounded-lg text-white"
                onClick={() => addToDatabase()}
              >
                Enviar para revisão
              </button>
            ) : (
              <WaitingBtn />
            )}

            <Link
              to="/admin"
              className="ml-2 font-medium mr-2 cursor-pointer border-b-2 border-gray-900  px-8 py-3 rounded-lg border-none"
            >
              Cancelar
            </Link>
          </div>
        </div>
        <div className="col-span-8 overflow-y-scroll">
          <CourseAd course={course} description={course.description} />
        </div>
      </div>
    </AdminLayout>
  );
}

