import React, { useState } from "react";
import { Search } from "react-feather";

const UserSearch = ({ studentNames, onUserSelect }) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(searchText);

    const results = Object.entries(studentNames).filter(([userId, name]) =>
      name.toLowerCase().includes(searchText)
    );

    setSearchResults(results.slice(0, 10));
  };

  return (
    <div className="w-1/5 bg-white p-4 rounded-lg">
      <div className="relative mb-4">
        <Search className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-400" />
        <input
          type="text"
          className="w-full pl-10 pr-2 py-2 rounded-md focus:outline-none bg-gray-100 border border-gray-200 text-gray-800"
          placeholder="Pesquisar..."
          value={searchText}
          onChange={handleSearch}
        />
      </div>
      {searchResults.map(([userId, name]) => (
        <div
          key={userId}
          className="bg-white p-2 mb-2 cursor-pointer rounded-md flex items-center"
          onClick={() => onUserSelect(userId)}
        >
          {/* Displaying user profile image */}
          <img
            src={`url_to_user_profile_image/${userId}`} // Replace with the actual URL or logic to get the user's profile image
            alt={`${name}'s profile`}
            className="w-6 h-6 rounded-full mr-2"
          />
          {name}
        </div>
      ))}
    </div>
  );
};

export default UserSearch;
