import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import {Lock} from "react-feather";
import { useParams } from 'react-router-dom';
import db, { useAuthState } from 'src/firebase';
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import useMercadoPago from "../../../hooks/useMercadoPago";
import logo_pay from '../../../images/logo_pay.svg'

import {
  doc,
  getDoc,
  addDoc,
  collection,
  getDocs,
  updateDoc,
  arrayUnion,
  query,
  where,
} from '@firebase/firestore';
import { useHistory } from 'react-router-dom';
import { Timestamp } from '@firebase/firestore';
import { useRole } from 'src/hooks/useRole';
import { completePayment } from '../../../pages/landingPage/Purchase';
import InputField from 'src/components/InputField';

const INITIAL_STATE = {
  cvc: "",
  cardExpirationMonth: "",
  cardExpirationYear: "",
  focus: "cardNumber",
  cardholderName: "",
  cardNumber: "",
  issuer: "",
};

function MercadoPagoForm({changeIndex, courseData}) {
  const [state, setState] = useState(INITIAL_STATE);
  const resultPayment = useMercadoPago(courseData);

  useEffect(() => {
    if (resultPayment && resultPayment.status === "approved") {
      changeIndex(index => index + 1);
    }
  }, [resultPayment]);  
  
  const handleInputChange = (e) => {
      setState({
          ...state,
          [e.target.dataset.name || e.target.name]: e.target.value,
      });
  };

  const handleInputFocus = (e) => {
      setState({ ...state, focus: e.target.dataset.name || e.target.name });
  };

  return (
    <div className="flex flex-col space-y-4">
      <Card
        cvc={state.cvc}
        expiry={state.cardExpirationMonth + state.cardExpirationYear}
        name={state.cardholderName}
        number={state.cardNumber}
        focused={state.focus}
        brand={state.issuer}
        className="mb-4 rounded-lg shadow-md"
      />
      <form id="form-checkout" className="space-y-4">
        <div className="form-control">
          <input
            type="tel"
            name="cardNumber"
            id="form-checkout__cardNumber"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="form-control flex space-x-4">
          <input
            type="tel"
            name="cardExpirationMonth"
            id="form-checkout__cardExpirationMonth"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="tel"
            name="cardExpirationYear"
            id="form-checkout__cardExpirationYear"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="tel"
            name="cvc"
            id="form-checkout__securityCode"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="form-control flex space-x-4">
          <input
            type="text"
            name="cardholderName"
            id="form-checkout__cardholderName"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="email"
            name="cardholderEmail"
            id="form-checkout__cardholderEmail"
            onFocus={handleInputFocus}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="form-control flex space-x-4">
          <select
            name="issuer"
            id="form-checkout__issuer"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></select>
          <select
            name="identificationType"
            id="form-checkout__identificationType"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></select>
        </div>
        <div className="form-control">
          <input
            type="text"
            name="identificationNumber"
            id="form-checkout__identificationNumber"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="form-control">
          <select
            name="installments"
            id="form-checkout__installments"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></select>
        </div>
        <div className="form-control">
          <button
            type="submit"
            id="form-checkout__submit"
            className="w-full p-3 text-white text-md bg-blue-600 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Pagar agora
          </button>
        </div>
        <div className="form-control">
          <progress
            value="0"
            className="w-full h-1 bg-gray-100 progress-bar"
          ></progress>
        </div>
      </form>
        {resultPayment && <p>{JSON.stringify(resultPayment)}</p>}
      </div>
  );
}

function Payment({ completePayment, changeIndex }) {
  const history = useHistory();
  const { course } = useParams();
  const role = useRole();
  const { user } = useAuthState();

  const [student, setStudent] = useState({ name: '', email: '' });

  const [courseData, setCourseData] = useState({
    title: '',
    description: '',
    price: 0,
    logo: '',
  });

  useEffect(() => {
    // Fetch course data from the database
    getDoc(doc(db, 'courses', course)).then((doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setCourseData({
          title: data.title,
          description: data.description,
          price: data.price,
          logo: data.logo,
        });
      }
    });
  }, [course]);

  useEffect(() => {
    if (user) {
      const fetchStudentData = async () => {
        const studentDoc = await getDoc(doc(db, 'students', user.uid));
        if (studentDoc.exists()) {
          const studentData = studentDoc.data();
          setStudent({
            name: studentData.name,
            email: studentData.email,
          });
        }
      };

      fetchStudentData();
    }
  }, [user]);

  return (
    <section className="relative md:py-10 overflow-hidden bg-white rounded-lg shadow-md border border-gray-200">
      <div className="container px-4 mx-auto">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-wrap -mx-4">

            <div className="w-full lg:w-1/2 px-4 pb-8 lg:mb-0">
              <div className="mx-auto text-center">
                <img src={logo_pay} className="md:h-8 h-7 mb-10 mx-auto" />
                <h3 className="font-heading text-center items-center justify-center align-middle text-xl text-gray-950 font-semibold mb-8 flex md:hidden mx-auto">
                  {courseData.title} | R$ {courseData.price}
                </h3>
                <div className="w-11/12 md:w-10/12 lg:w-11/12 mx-auto">
                  <MercadoPagoForm changeIndex={changeIndex} courseData={courseData} />
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/2 px-4 order-first md:order-last">

              <div className="p-4 mt-6 md:mt-0 mb-10 md:mb-0 bg-blue-100 text-blue-800 rounded-md flex items-center justify-center space-x-4"> {/* Center the icon and text */}
                <Lock />
                <span className="md:text-md text-sm">Pagamento protegido com segurança SSL</span>
              </div>

              <div className="md:block hidden max-w-lg mx-auto md:p-24 lg:mx-0 lg:max-w-2xl bg-gradient-to-r from-gray-100 to-white rounded-lg mt-4">
                <div className="flex pt-16 justify-center align-middle items-center">
                  <div className=" w-full">
                    <img
                      src={courseData.logo}
                      alt="Course logo"
                      className="w-32 h-32 mb-6 rounded-lg border border-gray-300"
                    />
                    <h4 className="font-heading text-md text-gray-500 font-regular">
                      Você está comprando:
                    </h4>
                    <h3 className="font-heading text-2xl text-gray-950 font-semibold mb-8">
                      {courseData.title} | R$ {courseData.price}
                    </h3>
                    <div className="md:flex">
                      <div className="text-md font-regular text-gray-500 line-clamp-4 overflow-hidden" dangerouslySetInnerHTML={{ __html: courseData.description }}/>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Payment;