import { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "@firebase/auth";
import InputField from "src/components/InputField";
import { Link } from "react-router-dom";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  function forgotPassword() {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage("Enviamos um email para " + email);
      })
      .catch((error) => {
        setErrorMessage("Algo de errado ocorreu. Tente novamente mais tarde.");
      });
  }

  return (
    <section className="py-10  h-screen overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="pt-16 pb-16 mx-auto bg-white rounded-3xl md:max-w-2xl border border-gray-200 px-6 md:px-0">
          <div className="md:max-w-md mx-auto">
            <div className="mb-10 text-center">
              <h2 className="font-heading mb-4 text-4xl tracking-tight text-gray-900 font-semibold">Esqueceu sua senha?</h2>
              <p className="text-md text-gray-600 pb-8">
                Preencha seu email e te enviaremos um email de recuperação com um link para alterar sua senha.
              </p>
            </div>
            <InputField
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="seuemail@email.com"
            />

            <p className="text-xs text-center mt-6 mb-3 text-red-500">
              {errorMessage}
            </p>
            
            <button
              className="w-full block px-8 py-3.5 text-md text-center text-white font-medium bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 rounded-lg"
              onClick={forgotPassword}
            >
              Recuperar minha senha
            </button>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
