import db from "src/firebase";
import { useState, Fragment } from "react";
import { doc, deleteDoc } from "@firebase/firestore";
import { Dialog, Transition } from "@headlessui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import CourseStatus from "src/components/statuses/CourseStatus";

export default function CourseSettings({ course, id }) {
  let [isOpen, setIsOpen] = useState(false);
  let history = useHistory();

  async function handleDelete() {
    await deleteDoc(doc(db, "courses", id));
    setIsOpen(false);
    history.push("/");
  }

  if (course == null) {
    return <h1>Carregando...</h1>;
  }

  return (
    <>
      <div className="mt-12">
        <label className="block mb-6 text-sm font-medium text-gray-700">
          Status do curso
        </label>
        <CourseStatus course={course} id={id} />

        <button
          className="mt-12 w-60 text-center transform  cursor-pointer font-medium text-md px-8 py-3 bg-red-500 rounded-lg text-white"
          onClick={() => setIsOpen(true)}
        >
          Deletar curso
        </button>
      </div>

      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Tem certeza?
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Tem certeza de que quer deletar esse curso? Todos os seus alunos perderão acesso a ele.
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="mr-3 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-lg transition duration-200 ease-in hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-600"
                    onClick={() => handleDelete()}
                  >
                    Deletar
                  </button>

                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900  border border-transparent rounded-lg transition duration-200 ease-in hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-600"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
