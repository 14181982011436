import { CourseBoard } from "src/components/CourseBoard";
import CoursesImg from "../../images/courses.png"

function Courses() {
  return (
    <div className="pt-8 md:pt-12">
      <section className="pb-8  overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 md:px-16 rounded-lg bg-courses bg-no-repeat bg-cover">
            <h2 className="font-heading mb-6 text-4xl text-white md:max-w-lg font-semibold">Aprenda medicina com quem entende do assunto</h2>
            <p className="mb-14 text-md text-gray-200 font-medium">Seus professores e médicos favoritos ensinam através da Medfy.</p>
            <div className="flex flex-wrap -m-2">

            </div>
          </div>
        </div>
      </section>

      <CourseBoard title={false} />
    </div>
  );
}

export default Courses;
