import React from "react";
import CourseTable from "src/components/tables/CourseTable";
import NoCourses from "src/components/emptyStates/NoCourses";
import { useCourses } from "src/hooks/useCourses";
import AdminLayout from '../../layouts/AdminLayout';
import Banner from '../../images/banner.png'

export default function AdminDashboard() {
  const courses = useCourses();

  if (!courses) {
    return <NoCourses />;
  }

  return (
    <AdminLayout>
      <div className="h-screen no-scrollbar md:mx-8 mx-4">
        <section class="pt-8">
          <div class="container mx-auto">
            <div class="relative pt-12 lg:py-18 bg-gradient-to-r from-gray-700 to-gray-900 px-8 md:px-16 rounded-2xl overflow-hidden">
              <div class="relative max-w-lg mx-auto lg:mx-0 mb-16 lg:mb-0">
                <h3 class="text-3xl font-medium text-white mb-3 tracking-tight" contentEditable="false">Estamos felizes em ter você aqui!</h3>
                <p class="font-regular text-gray-200 mb-6">Atualizações: O serviço da Medfy Studios está disponível. Agora você pode ter seu curso gravado e editado com a qualidade Medfy.</p>
              </div>
              <img class="hidden lg:block absolute bottom-0 right-0 md:max-w-2xl" src={Banner} contentEditable="false"/>
            </div>
          </div>
        </section>

        <div className="container overflow-hidden mx-auto mt-8 p-8 bg-white border border-gray-200 rounded-lg">
          <h3 className="text-2xl font-medium" to="/admin">
            Seus cursos
          </h3>

          <CourseTable courses={courses} />
        </div>

      </div>
    </AdminLayout>
  );
}
