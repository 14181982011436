import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Grid, Tag, Book, TrendingUp, Package, Settings, User, DollarSign } from 'react-feather';

const AdminLayout = ({ children }) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const items = [
    { name: 'Início', path: '/admin', icon: Grid },
    { name: 'Produtos', path: '/products', icon: Tag },
    { name: 'Vendas', path: '/sales', icon: TrendingUp },
    { name: 'Financeiro', path: '/payments', icon: DollarSign },
    { name: 'Serviços', path: '/services', icon: Package },
    { name: 'Configurações', path: '/edit/settings', icon: Settings },
  ];

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="bg-gray-100 w-64 min-h-screen px-2 hidden md:block">
        <ul className="list-none text-black p-4 pl-0">
          {items.map((item) => (
            <li className="my-2" key={item.name}>
              <NavLink
                to={item.path}
                className={`flex items-center text-black hover:bg-gray-200 rounded-lg p-3 transition duration-200 ease-in ${
                  activePath === item.path && 'bg-gray-200 text-black rounded-lg transition duration-200 ease-in'
                }`}
                activeClassName="text-black bg-gray-200"
              >
                <item.icon className="mr-4 w-5 h-5 text-gray-700" />
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex-1 overflow-y-scroll no-scrollbar">{children}</div>
      <div className="fixed bottom-0 left-0 right-0 bg-white py-2 md:hidden">
        <ul className="list-none flex justify-around p-0">
          {items.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.path}
                className="flex flex-col items-center text-black hover:bg-gray-200 rounded-lg p-3 transition duration-200 ease-in"
                activeClassName="text-black bg-gray-200"
              >
                <item.icon className="w-5 h-5 text-gray-700" />
                <span className="hidden md:block">{item.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminLayout;
