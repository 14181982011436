import { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  doc,
  getDoc,
  deleteDoc,
} from "@firebase/firestore";
import db, { useAuthState } from "src/firebase";
import { Link } from "react-router-dom";
import UserSearch from "./UserSearch";

export default function CommunityChat() {
  const { user } = useAuthState();
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [studentNames, setStudentNames] = useState({});
  const [displayedComments, setDisplayedComments] = useState([]);

  useEffect(() => {
    // Fetching comments and student names
    const fetchCommentsAndStudentNames = async () => {
      const commentsRef = collection(db, "comments");
      const q = query(commentsRef, orderBy("timestamp", "desc"));

      onSnapshot(q, async (querySnapshot) => {
        const commentsData = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));

        const userIds = Array.from(
          new Set(commentsData.map((comment) => comment.userId))
        );

        const fetchedStudentNames = await Promise.all(
          userIds.map(async (userId) => {
            const studentDoc = await getDoc(doc(db, "students", userId));
            return { [userId]: studentDoc.data().name };
          })
        );

        const studentNamesObj = Object.assign({}, ...fetchedStudentNames);

        setComments(commentsData);
        setStudentNames(studentNamesObj);
      });
    };

    fetchCommentsAndStudentNames();
  }, []);
  
  useEffect(() => {
    setDisplayedComments(comments.slice(0, 6));
  }, [comments]);

  const loadMoreComments = () => {
    setDisplayedComments(comments);
  };

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!commentText.trim()) return;
  
    const newComment = {
      userId: user.uid,
      username: user.displayName || user.email,
      text: commentText,
      timestamp: new Date(),
    };
  
    await addDoc(collection(db, "comments"), newComment);
  
    setCommentText("");
  };

  const handleUserSelect = (userId) => {
    // Handle user selection from search results
    // You can implement the logic to fetch user-specific comments here
  };

  const handleDeleteComment = async (commentId) => {
    const commentRef = doc(db, "comments", commentId);
    await deleteDoc(commentRef);
  };
  
  return (
    <section className="md:py-4 h-screen overflow-hidden transition transform ease-in-out delay-500">
    <div className="container mx-auto md:px-4 flex">
      <div className="mx-auto md:rounded-3xl md:max-w-6xl w-4/5">
        <div className="bg-white p-4 rounded-lg">
          <div>
          {displayedComments.map((comment) => (
                <div key={comment.id} className="bg-white p-4 mb-4 rounded-md flex items-start">
                  {user.uid === comment.userId && comment.studentImg ? (
                    <img
                      src={comment.studentImg}
                      alt={`${studentNames[comment.userId]}'s profile`}
                      className="w-10 h-10 rounded-full mr-2"
                    />
                  ) : (
                    <div className="w-10 h-10 rounded-full mr-2 bg-gray-300 flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4Z"
                        />
                      </svg>
                    </div>
                  )}
                  <div>
                    <div className="text-sm font-bold text-gray-800">
                      <Link to={`/sales/${comment.userId}`}>
                        {studentNames[comment.userId]}
                      </Link>
                    </div>
                    <div className="text-gray-500 text-xs">
                      {new Date(comment.timestamp.seconds * 1000).toLocaleString()}
                    </div>
                    <p className="mt-2 text-gray-800">{comment.text}</p>
                  </div>
                  {user.uid === comment.userId && (
                    <button
                      onClick={() => handleDeleteComment(comment.id)}
                      className="text-red-500 text-sm"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g id="feTrash0" fill="none" fill-rule="evenodd" stroke="none" stokeWidth="1"><g id="feTrash1" fill="currentColor" fill-rule="nonzero"><path id="feTrash2" d="M4 5h3V4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1h3a1 1 0 0 1 0 2h-1v13a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7H4a1 1 0 1 1 0-2Zm3 2v13h10V7H7Zm2-2h6V4H9v1Zm0 4h2v9H9V9Zm4 0h2v9h-2V9Z"/></g></g></svg>
                    </button>
                  )}
                </div>
              ))}
          </div>
          {displayedComments.length < comments.length && (
            <button
              onClick={loadMoreComments}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
            >
              Carregar mais
            </button>
          )}
          <form
            onSubmit={handleSubmitComment}
            className="flex items-center space-x-4 mt-4"
          >
            <textarea
              className="resize-none w-full p-2 rounded-md focus:outline-none bg-gray-100 border border-gray-200 text-gray-800"
              placeholder="Digite seu comentário..."
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              rows="2"
            ></textarea>
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
      <UserSearch studentNames={studentNames} onUserSelect={handleUserSelect} />
    </div>
  </section>
  );
}
