import { useState, useEffect } from "react";
import InputField from "src/components/InputField";
import db from "src/firebase";
import { addDoc, collection, Timestamp } from "@firebase/firestore";
import { useHistory } from "react-router-dom";

function SignUpInstructor() {
  const [submitted, setSubmitted] = useState(false);
  const [instructor, setInstructor] = useState("");
  const [website, setWebsite] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [allFieldsChecked, setAllFieldsChecked] = useState(false); // Define setAllFieldsChecked function here
  const history = useHistory();

  const handleFormSubmit = () => {
    addDoc(collection(db, "applications"), {
      instructor: instructor,
      website: website,
      email: email,
      phone: phone,
      time: Timestamp.now(),
    }).then(() => {
      window.scrollTo(0, 0);
      setSubmitted(true);
    });
  };

  useEffect(() => {
    setAllFieldsChecked(
      instructor.length > 0 &&
      website.length > 0 &&
      email.length > 0 &&
      phone.length > 0
    );
  }, [instructor, website, email, phone]);

  return (
    <>
    <section class="bg-gray-100 overflow-hidden">
      <div class="flex flex-wrap -m-8">
        <div class="w-full md:w-1/2 p-8">
          <div class="container px-4 mx-auto">
            <div class="flex flex-wrap">
              <div class="w-full">
                <div class="md:max-w-lg mx-auto pt-16 md:pb-24">
                  <div class="md:max-w-sm">
                    <h2 class="mb-4 text-4xl text-center md:text-left md:text-5xl font-bold font-heading tracking-px-n leading-tight">Agende uma demonstração</h2>
                    <p class="mb-9 text-center md:text-left text-gray-600 font-medium leading-relaxed">Entraremos em contato assim que possível.</p>
                  </div>
                  
                  {submitted ? (
                    <div className="pt-32 pb-80 z-30">
                      <h2 className="font-semibold md:text-5xl text-4xl text-center mb-6">
                        Obrigado! 🎉
                      </h2>

                      <p className="text-center text-lg mx-auto">
                        Entraremos em contato em breve.
                      </p>
                    </div>
                  ) : (
                    <div className="md:mt-16 pt-6 px-6 pb-6 md:px-16 text-left mx-auto">
                      <InputField
                        type="text"
                        label="Qual o seu nome?"
                        value={instructor}
                        onChange={(e) => setInstructor(e.target.value)}
                        placeholder="Jorge P."
                      />

                      <InputField
                        type="text"
                        label="Website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        placeholder="www.site.com"
                      />

                      <InputField
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="seuemail@email.com"
                      />

                      <InputField
                        type="text"
                        label="Número de telefone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="+55 11 XXXXX-XXXX"
                      />

                      {allFieldsChecked ? (
                        <div
                          className="cursor-pointer mt-8 w-full text-center bg-blue-600 text-white transition duration-200 ease-in hover:bg-blue-700 font-medium px-4 py-3 rounded-lg text-md"
                          type="submit"
                          onClick={handleFormSubmit}
                        >
                          Enviar
                        </div>
                      ) : (
                        <div className="mt-8 w-full text-center bg-gray-200 text-gray-700 ease-in font-medium cursor-not-allowed px-4 py-3 rounded-lg text-md">
                          Aguardando respostas... 
                        </div>
                      )}
                      <p className="text-xs text-center mt-6">
                        Ao aplicar para criar uma conta, você concorda com a Política de Privacidade e os Termos e Condições da Medfy.
                      </p>
                    </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 hidden md:block p-8">
          <div class="flex flex-col justify-center h-full bg-blue-600">
            <div class="p-16 text-center">
              <img class="mx-auto md:w-1/2" src="https://firebasestorage.googleapis.com/v0/b/webapp-e2f91.appspot.com/o/landing%2Fhero.webp?alt=media&token=b0ca847c-ac54-4196-a016-f1c192bff92e" alt=""/>
              <h2 class="mb-5 text-3xl text-white font-medium tracking-px-n leading-tight">Plataforma completa para médicos</h2>
              <p class="mb-24 text-lg text-white text-opacity-80 font-regular leading-normal md:max-w-md mx-auto">Venda seu curso, faça aulas ao vivo, receba pagamentos e muito mais através da Medfy.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default SignUpInstructor;
