import React from "react";
import { Link } from "react-router-dom";
import AdminLayout from '../../layouts/AdminLayout';
import { Video, Link as LinkIcon, BookOpen, Box, } from 'react-feather';

export default function AdminProducts() {

  return (
    <AdminLayout>
       <section class="pt-16 h-screen md:mx-8 mx-4">
        <div class="container px-4 mx-auto">
          <h2 className="text-2xl tracking-tight font-semibold text-gray-900 leading-none line-clamp-1 overflow-hidden mb-8">Que tipo de produto você quer criar?</h2>
          <div class="flex flex-wrap -mx-4 -mb-6">
            <Link to="/products/new-course" class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div class="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div class="inline-flex items-center justify-center w-16 h-16 mb-6 bg-blue-200 rounded-lg">
                  <Video className="text-blue-600"/>
                </div>
                <div className="flex flex-row mb-3">
                  <h4 class="text-xl text-gray-900 font-semibold">Curso</h4>
                  <p className="flex text-xs font-medium ml-2 px-3 pt-1.5 bg-blue-200 text-center rounded-full text-blue-600 animate-pulse">Recomendado</p>
                </div>
                <p class="text-gray-500 font-regular text-sm">Melhor para conteúdos gravados, seu aluno recebe o acesso à Área de Membros personalizada para vídeos.</p>
              </div>
            </Link>
            <Link to="/products/new-payment-link" class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div class="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div class="inline-flex items-center justify-center w-16 h-16 mb-6 bg-blue-200 rounded-lg">
                  <LinkIcon className="text-blue-600"/>
                </div>
                <h4 class="text-xl text-gray-900 font-semibold mb-3">Link de pagamentos</h4>
                <p class="text-gray-500 font-regular text-sm">Receba pagamentos de maneira rápida e segura por uma aula, mentoria, ao material que foi vendido.</p>
              </div>
            </Link>
            <Link to="/products/new-ebook" class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div class="relative p-6 bg-white border border-gray-200 rounded-xl">
                <div class="inline-flex items-center justify-center w-16 h-16 mb-6 bg-blue-200 rounded-lg">
                  <BookOpen className="text-blue-600"/>
                </div>
                <h4 class="text-xl text-gray-900 font-semibold mb-3">Ebook, Material de estudo</h4>
                <p class="text-gray-500 font-regular text-sm">Melhor para conteúdos em texto, seu aluno recebe o acesso ao Ebook ou material dentro da plataforma.</p>
              </div>
            </Link>
            <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
              <div class="relative p-6 border border-gray-300 border-dashed rounded-xl">
                <div class="inline-flex items-center justify-center w-16 h-16 mb-6 bg-gray-200 rounded-lg">
                  <Box className="text-gray-500"/>
                </div>
                <h4 class="text-xl text-gray-500 font-semibold mb-3">Produto físico (Em breve)</h4>
                <p class="text-gray-300 mb-6 font-regular text-sm">Crie uma linha de produtos físicos e venda para seus clientes através da sua própria loja Medfy.</p>
                <a class="px-8 py-3 border border-transparent font-medium rounded-lg text-gray-500 bg-gray-200 md:py-2 text-md md:px-10 transform cursor-not-allowed">Em breve</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
}
