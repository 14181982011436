import {Folder, Plus} from "react-feather";
import { Link } from "react-router-dom";

export default function NoCourses() {
  return (
    <div className="text-center w-5/12 mx-auto my-56">
      <Folder className="text-6xl text-gray-300" />
      <h1 className="text-2xl mt-4">Nenhum curso por enquanto.</h1>
      <p className="mb-8">Crie seu primeiro curso e ele aparecerá aqui.</p>
      <Link
        to="/products/new-coursee"
        className="hover:opacity-80 flex-row  w-44 cursor-pointer font-semibold text-md justify-center px-8 py-3 bg-blue-600 rounded-lg text-gray-900"
      >
        <Plus className="mr-3" />
        Abrir vaga
      </Link>
    </div>
  );
}
