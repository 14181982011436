import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Video, MessageCircle, Users, Settings, Inbox } from 'react-feather';

const StudentLayout = ({ children }) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const items = [
    { name: 'Cursos', path: '/purchases', icon: Video },
    //{ name: 'Social', path: '/feed', icon: Inbox },
    { name: 'Comunidade', path: '/messages', icon: Users },
    //{ name: 'Perfil', path: '/profile', icon: User },
    { name: 'Configurações', path: '/student/settings', icon: Settings },
  ];

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="bg-gray-900 w-64 min-h-screen px-2 hidden md:block">
        <ul className="list-none text-white p-4 pl-0">
          {items.map((item) => (
            <li className="my-2" key={item.name}>
              <NavLink
                to={item.path}
                className={`flex items-center text-white hover:bg-gray-800 rounded-lg p-3 transition duration-200 ease-in ${
                  activePath === item.path && 'bg-white text-white rounded-lg transition duration-200 ease-in'
                }`}
                activeClassName="text-white bg-gray-700"
              >
                <item.icon className="mr-4 w-5 h-5 text-gray-500" />
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex-1 overflow-y-scroll no-scrollbar">{children}</div>
      <div className="fixed bottom-0 left-0 right-0 bg-gray-900 py-2 md:hidden">
        <ul className="list-none flex justify-around p-0">
          {items.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.path}
                className="flex flex-col items-center text-white hover:bg-gray-800 rounded-lg p-3 transition duration-200 ease-in"
                activeClassName="text-white bg-gray-700"
              >
                <item.icon className="w-5 h-5 text-gray-500" />
                <span className="hidden md:block">{item.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StudentLayout;
