import db, { useAuthState } from "src/firebase";
import { useState, useEffect } from "react";
import AccountSettings from "src/components/settings/AccountSettings";
import PasswordSettings from "src/components/settings/PasswordSettings";
import InstructorSettings from "src/components/settings/InstructorSettings";
import { doc, getDoc } from "@firebase/firestore";
import AdminLayout from '../../layouts/AdminLayout';

function AdminSettings() {
  let [active, setActive] = useState(0);
  let [profile, setProfile] = useState();
  const { user } = useAuthState();

  useEffect(() => {
    getDoc(doc(db, "instructors", user?.uid)).then((doc) => {
      setProfile(doc.data());
    });
  }, []);

  return (
    <AdminLayout>
      <div className="">
        <div className="grid grid-cols-12 mt-8 md:mt-12 w-11/12 mx-auto min-h-screen gap-16">
          <div className="col-span-3">
            <button
              className={`${
                active === 0 ? "bg-white mb-6 border border-gray-200" : " border border-gray-200 mb-6 text-gray-600"
              } w-full text-left font-semibold cursor-pointer px-8 py-3 rounded-lg block`}
              onClick={() => setActive(0)}
            >
              Editar seu perfil
            </button>

            <button
              className={`${
                active === 1 ? "bg-white border border-gray-200 mb-6" : " border border-gray-200 mb-6 text-gray-600"
              } w-full text-left font-semibold cursor-pointer px-8 py-3 rounded-lg block`}
              onClick={() => setActive(1)}
            >
              Configurações da conta
            </button>

            <button
              className={`${
                active === 2 ? "bg-white border border-gray-200" : " border border-gray-200 text-gray-600"
              } w-full text-left font-semibold cursor-pointer px-8 py-3 rounded-lg block`}
              onClick={() => setActive(2)}
            >
              Configurações de senha
            </button>
          </div>
          <div className="col-span-7">
            {active === 0 ? (
              <InstructorSettings user={user} profile={profile} />
            ) : active === 1 ? (
              <AccountSettings user={user} />
            ) : (
              <PasswordSettings user={user} />
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminSettings;
