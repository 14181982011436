import { Link } from "react-router-dom";
import logo_black from "../images/logo_black.svg";

export function Footer() {
  return (
    <div className="bg-white text-gray-950 text-left">
      <div className="w-10/12 mx-auto pt-20 pb-8 flex flex-col sm:flex-row space-y-2 justify-start">
        <div className="w-full sm:w-2/5 pr-6 flex flex-col space-y-2 pb-6">
          <Link className="flex pt-1" to="/">
            <img className="h-8 pb-2" src={logo_black} alt="Logo" />
          </Link>
          <h2 className="text-sm text-gray-400 font-medium -mt-2">
            Aster LTDA (Medfy™) <br/>
            CNPJ: 43.298.443/0001-13 <br/>
            Endereço: Barão de Camargos 135, <br/>
            Uberlândia - MG CEP 38400-160
          </h2>
          <a
            className="w-44 text-sm font-medium text-gray-400 border-b-2 border-gray-950 hover:text-gray-700 transition duration-200 ease-in"
            href="mailto:contato@medfy.net"
          >
            contato@medfy.net
          </a>
        </div>
        <div className="w-full sm:w-1/5 flex flex-col space-y-2 pb-6">
          <h2 className="text-lg font-light">Explore</h2>
          <Link
            className="w-32 text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            to="/courses"
          >
            Cursos
          </Link>
          <Link
            className="w-40 text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            to="/instructors"
          >
            Instrutores
          </Link>
          <Link
            className="w-32 text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            to="/sign-in"
          >
            Entrar
          </Link>
        </div>
        <div className="w-full sm:w-1/5 flex flex-col space-y-2 pb-6">
          <h2 className="text-lg font-light">Para médicos</h2>
          <Link
            className="w-32 text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            to="/sign-in"
          >
            Entrar
          </Link>
          <Link
            className="w-40 text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            to="/sign-up/new-instructor"
          >
            Cadastre-se
          </Link>
        </div>
        <div className="w-full sm:w-1/5 flex flex-col space-y-2 pb-6">
          <h2 className="text-lg font-light">Medfy</h2>
          <Link
            className="w-32 text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            to="/about"
          >
            Sobre nós
          </Link>
          <Link
            className="text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            to="/terms-of-service"
          >
            Termos de serviço
          </Link>
          <Link
            className="text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            to="/privacy-policy"
          >
            Política de privacidade
          </Link>
        </div>
        <div className="w-full sm:w-1/5 flex flex-col space-y-2 pb-6">
          <h2 className="text-lg font-light">Siga-nos</h2>
          <a
            className="w-32 text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            href=""
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <a
            className="w-32 text-gray-400 hover:text-gray-700 transition duration-200 ease-in font-medium text-sm"
            href=""
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
}
