export default function PurchaseNavigation({ index, changeIndex, isComplete }) {
  return (
    <div className="flex justify-center mt-8">
      {index !== 0 && index !== 1 && (
        <button
          className="bg-gray-200 px-4 py-2 rounded-md"
          onClick={() => changeIndex(index - 1)}
        >
          Voltar
        </button>
      )}
      {index !== 1 && (
        <button
          className={`${
            isComplete(index) ? "h-full md:w-5/12 w-full mx-4 py-3 mt-0.5 text-center text-white font-medium bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 focus:ring-4 focus:ring-blue-800 rounded-lg text-md border border-blue-600 " : "h-full mx-4 py-3 mt-0.5 text-center md:w-5/12 w-full text-gray-500 font-medium bg-gray-200 cursor-not-allowed rounded-lg text-md"
          } px-4 py-2 rounded-md`}
          onClick={() => {
            if (isComplete(index)) changeIndex(index + 1);
          }}
        >
          Continuar para o pagamento
        </button>
      )}
    </div>
  );
}
