import db from "src/firebase";
import "src/editor.css";
import { doc, getDoc } from "@firebase/firestore";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CourseAd from "src/components/CourseAd";
import NoCourse from "src/components/emptyStates/NoCourse";

function Course() {
  const [course, setCourse] = useState();
  const url = window.location.href;
  const id = url.split("/")[url.split("/").length - 1];

  useEffect(() => {
    getDoc(doc(db, "courses", id)).then((doc) => setCourse(doc.data()));
  }, []);

  if (!course) {
    return <NoCourse />;
  }

  return (
    <>
      <div className="md:w-10/12 mx-auto mt-8 md:mt-16 pb-20 ">
        <section className="py-4 pb-16 px-4 mx-4 md:px-8 border border-gray-200 rounded-lg bg-white">
          <div className="container mx-auto px-4">
            <div className="max-w-md md:max-w-none mx-auto">
              <div className="flex flex-wrap mt-10 -mx-4">
                <div className="w-full md:w-1/3 mb-12 md:mb-0 px-4">
                  <div className="max-w-3xl">
                    <div className="lg:flex h-full">
                      <div>
                        <img className="block rounded-lg h-full w-full max-w-2xl border border-gray-200" src={course?.logo} alt="Imagem do curso"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-2/3 px-4">
                  <div className="p-8 h-full border border-gray-200 rounded-lg">
                    <div className="pb-8 border-b border-gray-200">
                      <h1 className="font-heading font-semibold text-3xl text-gray-900 mb-3">{course?.title || "Título do curso"}</h1>
                      <div className="my-8">
                        <div className="text-gray-500 font-normal" dangerouslySetInnerHTML={{ __html: course?.description }}></div>
                      </div>
                      <div className="inline-flex items-center justify-center">
                        <h4 className="text-xl md:text-2xl font-semibold text-gray-900 mr-8">R$ {course?.price || "0000"}</h4>
                        <Link
                          to={`${id}/purchase`}
                          className="px-8 py-3 border border-transparent font-medium rounded-lg text-white bg-blue-600 transition duration-200 ease-in hover:bg-blue-700 md:py-2 text-md md:px-10 transform"
                        >
                          Comprar
                        </Link>
                      </div>
                    </div>
                    <div className="pt-8 pb-8">
                      <div className="max-w-xs xl:pr-20">
                        <span className="block text-gray-900 font-semibold mb-4">Detalhes</span>
                        <div className="flex mb-2">
                          <span className="text-gray-900 font-semibold">Categoria</span>
                          <span className="ml-auto text-sm text-gray-400">{course.category || "Neurologia"}</span>
                        </div>
                        <div className="flex mb-2">
                          <span className="text-gray-900 font-semibold">Nível do curso</span>
                          <span className="ml-auto text-sm text-gray-400">{course.level || "Residência"}</span>
                        </div>
                        <div className="flex mb-2">
                          <span className="text-gray-900 font-semibold">Módulos</span>
                          <span className="ml-auto text-sm text-gray-400">{course.modules || "0"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withRouter(Course);
