import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import PaymentLinkCreator from "src/components/PaymentLinkCreator";

export default function AdminAddPaymentLink() {
  return (
    <>
      <PaymentLinkCreator />
    </>
  );
}
