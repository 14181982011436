import db, { useAuthState } from "src/firebase";
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  query,
  where,
} from "@firebase/firestore";
import { useHistory } from "react-router-dom";
import ProgressBar from "src/components/ProgressBar";
import PurchaseNavigation from "src/components/purchase/PurchaseNavigation";
import Student from "src/components/purchase/steps/Student";
import Payment from "src/components/purchase/steps/Payment";
import { useState, useEffect } from "react";
import { addDoc, collection, Timestamp } from "@firebase/firestore";
import { useRole } from "src/hooks/useRole";

export default function Purchase() {
  const history = useHistory();
  const url = window.location.href;
  const [paymentComplete, setPaymentComplete] = useState(false);
  const course = url.split("/")[url.split("/").length - 2];
  const role = useRole();
  const { user } = useAuthState();

  let indices = 2;
  let [index, setIndex] = useState(0);
  const [student, setStudent] = useState({});
  let addStudent = (value) => setStudent(value);
  let progress = Math.floor((100 / (indices - 1)) * index);

  useEffect(() => {
    if (role === "student") {
      getDoc(doc(db, "students", user.uid)).then((doc) => {
        let data = doc.data();
        setStudent({
          ...student,
          name: data.name,
          email: data.email,
        });
      });
    }
  }, [role]);

  useEffect(() => {
    if (role === "student" && index === 3) {
      addToDatabase();
    }
    
    if (index === 2) {
      addToDatabase();
    }
  }, [index]);

  function changeIndex(value) {
    window.scrollTo(0, 0);
    setIndex(value);
  }

  function isComplete(index) {
    if (index === 0) {
      if (student.name && student.email) {
        return true;
      }
    } else if (index === 1) {
      return paymentComplete;
    }
  
    return false;
  }  

  async function addToDatabase() {
    await addDoc(collection(db, "purchases"), {
      student: student,
      course: course,
      time: Timestamp.now(),
    }).then((purchaseRef) => {
      const q = query(
        collection(db, "students"),
        where("email", "==", student.email)
      );

      // gets the student(s) that has the same email.
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          updateDoc(doc.ref, {
            purchases: arrayUnion(purchaseRef.id),
          });
        });
      });

      history.push(`/purchase-complete`);
    });
  }

  function generateStep(value) {
    switch (value) {
      case 0:
        return <Student student={student} addStudent={addStudent} />;
      case 1:
        return <Payment changeIndex={changeIndex} />;
    }
  }
  
  return (
    <div className="pt-16 h-screen">
      <canvas id="my-canvas" className="absolute z-0"></canvas>
      <div className="md:px-8 px-2 pb-8 text-left md:w-9/12 mx-auto">
        {generateStep(index)}
        <PurchaseNavigation
          index={index}
          changeIndex={changeIndex}
          isComplete={isComplete}
        />
      </div>
    </div>
  );
} 

export const completePayment = () => {

};