import {ArrowRight} from "react-feather";

import db from "src/firebase";
import { useState, useEffect } from "react";
import { getDocs, collection, query, where } from "@firebase/firestore";

import { Link } from "react-router-dom";
import Loader from "./Loader";

export function CourseBoard({ title }) {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const items = [];

    const q = query(collection(db, "courses"), where("status", "==", "Disponível"));

    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        items.push(doc);
      });
      if (title) {
        setCourses(items.slice(0, 6));
      } else {
        setCourses(items);
      }
    });
  }, []);

  if (courses.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <div className="container mx-auto px-4 pb-8">
        <div className="">
          {title ? (
            <div className="block pt-4">
              <h2 className="md:text-4xl text-4xl font-semibold text-gray-900 text-center md:pb-16 pb-12 mt-8 md:mt-16">
                Cursos em destaque
              </h2>
            </div>
          ) : null}

          <div className="grid lg:grid-cols-3 gap-6 grid-cols-1">
            {courses.map((course, id) => (
              <div
                key={id}
                className="transform ease-in w-full bg-white border border-gray-200 rounded-lg p-6 text-left"
              >
                <div className="flex items-center text-left pb-4">
                  <img
                    className="w-14 h-14 rounded-lg border border-gray-200 mr-4"
                    src={course.data().logo}
                    alt="Logo"
                  />
                  <div>
                    <p className="text-xl font-semibold text-gray-900 leading-none">
                      {course.data().title}
                    </p>
                    <p className="text-md text-gray-500">
                      {course.data().instructor}
                    </p>
                  </div>
                </div>
                <p className="pl-1 pt-1">
                  <span className="text-lg font-semibold">
                  R$ {course.data().price}
                  </span>
                </p>
                <p className="pl-1 pt-1">
                  <span className="font-semibold mr-3 text-lg">
                    {course.data().modules}
                  </span>
                  <span className="font-regular text-md text-gray-500">
                    módulos
                  </span>
                </p>
                <p className="pl-1 pt-1">
                  <span className="font-regular text-md text-gray-500">
                    {" "}
                    {course.data().level}
                  </span>
                </p>

                <div className="flex items-center flex-col md:flex-row pt-6">
                  <Link
                    to={`/courses/${course.id}`}
                    className="transition duration-200 ease-in flex cursor-pointer hover:bg-gray-900 w-full md:w-auto items-center font-medium text-md justify-center px-8 py-3 bg-gray-800 rounded-lg mb-4 md:mb-0 md:mr-4 text-white"
                  >
                    Sobre
                  </Link>

                  <Link
                    to={`/instructors/${course.data().instructorId}`}
                    className="block z-10 w-full md:w-48 md:mx-0 text-gray-900 font-medium rounded-lg items-center justify-center text-center py-3 text-base border-gray-200 border hover:bg-gray-100 transition duration-200 ease-in"
                  >
                    Sobre o instrutor
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {title ? (
            <div className="w-72 mt-16 mx-auto">
              <Link
                to="courses"
                className="transition duration-200 ease-in flex cursor-pointer items-center font-semibold text-md justify-center px-8 py-3 mb-8 md:mb-0 bg-gray-900 rounded-lg text-light"
              >
                Ver todos os cursos
                <ArrowRight
                  className="ml-3 mb-0.5 text-sm"
                  icon={ArrowRight}
                />
              </Link>
            </div>
          ) : null}

        </div>
      </div>
    </>
  );
}
