export default function InputField({
  label,
  value,
  onChange,
  className,
  placeholder,
  type,
}) {
  return (
    <div className={className}>
      <label className="block text-gray-900 text-sm font-semibold mb-2">{label}</label>
      <input
        className="block border border-grey-light w-full p-3 pl-6 rounded-lg mb-4 focus:ring-gray-900 focus:border-gray-900"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
