import React from "react";
import AdminLayout from '../../layouts/AdminLayout';

export default function AdminCourses() {

  return (
    <AdminLayout>
    </AdminLayout>
  );
}
