import React from "react";
import StudentLayout from "src/layouts/StudentLayout";

export default function StudentFeed() {
  return (
    <StudentLayout>
      <div className="flex justify-center w-screen h-screen px-4 text-gray-700 bg-gray-100">
        <div className="flex w-full max-w-screen-lg">
          <div className="flex flex-col flex-grow border-l border-r border-gray-300">
            <div className="flex justify-between flex-shrink-0 px-8 py-4 border-b border-gray-300">
              <h2 className="text-2xl font-semibold">Feed</h2>
              <button className="flex items-center h-10 px-4 text-sm bg-blue-600 rounded-md text-white hover:bg-blue-600">Novo post</button>
            </div>
            <div className="flex-grow h-0 overflow-auto">
              <div className="flex w-full p-8 border-b-4 border-gray-300">
                <span className="flex-shrink-0 w-12 h-12 bg-gray-400 rounded-full"></span>
                <div className="flex flex-col flex-grow ml-4">
                  <textarea className="p-3 bg-transparent border border-gray-500 rounded-md" name="" id=""  rows="3" placeholder="Digite seu post aqui"></textarea>
                  <div className="flex justify-between mt-2">
                    <button className="flex items-center h-10 px-3 text-xs rounded-md hover:bg-gray-200">Adicionar a</button>
                    <button className="flex items-center h-10 px-3 text-xs rounded-md bg-blue-600 text-white hover:bg-blue-600">Postar</button>
                  </div>
                </div>
              </div>
              <div className="flex w-full p-8 border-b border-gray-300">
                <span className="flex-shrink-0 w-12 h-12 bg-gray-400 rounded-full"></span>
                <div className="flex flex-col flex-grow ml-4">
                  <div className="flex">
                    <span className="font-semibold">Nome de usuário</span>
                    <span className="ml-1">@username</span>
                    <span className="ml-auto text-sm">Postado 1 hora atrás</span>
                  </div>
                  <p className="mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <a className="underline" href="#">#hashtag</a></p>
                  <div className="flex items-center justify-center h-64 mt-2 bg-gray-200">
                    <span className="font-semibold text-gray-500">Imagem</span>
                  </div>
                  <div className="flex mt-2">
                    <button className="text-sm font-semibold">Curtir</button>
                    <button className="ml-2 text-sm font-semibold">Responder</button>
                    <button className="ml-2 text-sm font-semibold">Compartilhar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-shrink-0 w-1/4 py-4 pl-4">
            <input className="flex items-center h-10 px-3 border border-gray-500 rounded-md" type="search" Placeholder="Buscar…"></input>
            <div>
              <h3 className="mt-6 text-lg font-semibold">Em alta</h3>
              {/* Repeated trending component */}
            </div>
          </div>
        </div>
      </div>
</StudentLayout>
);
}