function Faq() {

  return (
    <section className="py-10 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="relative py-16 px-8 overflow-hidden rounded-3xl">
          <img className="absolute bottom-0 left-1/2 transform -translate-x-1/2" src="zanrly-assets/images/faq/pattern-gray2.svg" alt=""/>
          <div className="relative md:max-w-7xl mx-auto">
            <div className="md:max-w-xl mb-10">
              <span className="inline-block mb-5 text-sm text-blue-600 font-semibold uppercase">Perguntas frequentes</span>
              <h2 className="font-heading text-4xl text-gray-900 tracking-tight font-semibold">Helpdesk - Tire suas dúvidas</h2>
            </div>
            <div className="flex flex-wrap -m-3">
              <div className="w-full p-3">
                <div className="p-10 bg-white border border-gray-200 rounded-lg">
                  <div className="flex flex-wrap -m-2">
                    <div className="w-full md:w-1/2 p-2">
                      <h3 className="font-heading text-xl text-gray-900 font-semibold">Como faço para me cadastrar na plataforma?</h3>
                    </div>
                    <div className="w-full md:w-1/2 p-2">
                      <p className="text-gray-500 font-medium" contentEditable="false">Para se cadastrar, basta clicar no botão acima em "Cadastrar-se". Em seguida, preencha as informações solicitadas e crie sua conta.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="p-10 bg-white border border-gray-200 rounded-lg">
                  <div className="flex flex-wrap -m-2">
                    <div className="w-full md:w-1/2 p-2">
                      <h3 className="font-heading text-xl text-gray-900 font-gray-950">Quais são as formas de pagamento aceitas?</h3>
                    </div>
                    <div className="w-full md:w-1/2 p-2">
                      <p className="text-gray-500 font-medium"> As formas de pagamento aceitas incluem cartões de crédito e débito. O Pix será incluido como forma de pagamento em breve.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="p-10 bg-white border border-gray-200 rounded-lg">
                  <div className="flex flex-wrap -m-2">
                    <div className="w-full md:w-1/2 p-2">
                      <h3 className="font-heading text-xl text-gray-900 font-gray-950">Como encontro o curso que estou procurando?</h3>
                    </div>
                    <div className="w-full md:w-1/2 p-2">
                      <p className="text-gray-500 font-medium">Se você veio pela indicação do seu professor, pode acessar o perfil dele na aba "Instrutores" ou pelo link disponibilizado. Além disso, é possível encontrar cursos recomendados ou em destaque na aba "Cursos".</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="p-10 bg-white border border-gray-200 rounded-lg">
                  <div className="flex flex-wrap -m-2">
                    <div className="w-full md:w-1/2 p-2">
                      <h3 className="font-heading text-xl text-gray-900 font-gray-950">Posso acessar os cursos em qualquer dispositivo?</h3>
                    </div>
                    <div className="w-full md:w-1/2 p-2">
                      <p className="text-gray-500 font-medium">A Medfy pode ser acessada pelo seu computador, smartphone ou tablets.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="p-10 bg-white border border-gray-200 rounded-lg">
                  <div className="flex flex-wrap -m-2">
                    <div className="w-full md:w-1/2 p-2">
                      <h3 className="font-heading text-xl text-gray-900 font-gray-950">Como faço para obter suporte se tiver problemas?</h3>
                    </div>
                    <div className="w-full md:w-1/2 p-2">
                      <p className="text-gray-500 font-medium">Se você não encontrou a resposta que procurava aqui, nos envie um chat pelo botão do canto inferior direito ou telefone para contato direto com a equipe de suporte.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
